/* second step of redux */
export const productsDetailsReducer = (state = { product: {} }, action) => {
    /* reducers are functions that take the actions process them and store the results in the store, and thevresults provided are the history of the products[] and the action of the new requested product */
    /* initial state is empty array for products, and action is to dispatch the payload to the state */
    switch (action.type) {
      /* check for the three action types 1 request to the server 2 response 3 error and do something with each type, it is set by hooks on productAction.js */
      case 'PRODUCT_DETAILS_REQUEST':
        return { loading: true, product: {} };
      //this case is set before the request to the backend, and we set the loading to true here and products to empty
      case 'PRODUCT_DETAILS_SUCCESS':
        //////console.log(`from product details Reducer success ${JSON.stringify(action.payload)}`);
        return { loading: false, product: action.payload };
      // this case is set after we get success response. and we set the loading to false here and products to the response.data
      case 'PRODUCT_DETAILS_FAIL':
        //////console.log(`from productReducer reject ${JSON.stringify(action.payload)}`);
  
        return { loading: false, error: [action.payload] };
      // this case is set after we get reject response. and we set the loading to false here and products to the error
      default:
        return state;
      /* default return the initial state */
    }
  };
  