import './signIn.css';
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { signInWithGooglePopup, checkUser } from '../../utils/firebase';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { anSignInAction, appleSignInAction, facebookSignInAction, googleSignInAction, regularSignInAction } from '../../actions/userAction'
import { useEffect, useState } from 'react';
import axios from 'axios'
import { isEmpty } from "lodash";
import { addToCartAction } from '../../actions/addToCartAction';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import LoadingSpinner from '../../components/Spinner/Spinner';

const SignIn = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false);

    /* this is the params we write after ? im url. proceed to checout buttom from the csrt page will navigate to navigate('/sign-in?redirect=shipping' so if we came here from proceed to checkout, we want to go to the checkout after signin, otyerwise it means we came from the header andvwe need to redirect to the home page after we finish */
    //const redirect= searchParams.get('redirect')?'/shipping':'/';
    const redirect = searchParams.get('redirect') ? '/shipping' : '/';
    const cartId = localStorage.getItem('cartId') ? JSON.parse(localStorage.getItem('cartId')) : ''

    ////console.log(`from signinjsx redirect ${redirect}`)
    /* from signinjsx redirect shipping */
    const user = useSelector((state) => state.user)
    const { loading, error, userInfo } = user
    const logout = () => {
        localStorage.clear()
    }
    const googleSignIn = async () => {
        dispatch(googleSignInAction())
        ////console.log(`from signin jsx track user info after dispatch ${JSON.stringify(userInfo)}`)
        setIsLoading(true)

    }
    const appleSignIn = async () => {
        dispatch(appleSignInAction())
        ////console.log(`from signin jsx track user info after dispatch ${JSON.stringify(userInfo)}`)
        setIsLoading(true)

    }
    const facebookSignIn = async () => {
        dispatch(facebookSignInAction())
        ////console.log(`from signin jsx track user info after dispatch ${JSON.stringify(userInfo)}`)
        setIsLoading(true)

    }
    const anSignIn = async () => {
        dispatch(anSignInAction())
        ////console.log(`from signin jsx track user info after dispatch ${JSON.stringify(userInfo)}`)
        
        setIsLoading(true)
    }

    const regularSignIn = (data) => {
        dispatch(regularSignInAction(data))

    };
    //response log below
    /* {
"data": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6ImNvbmRvbXNob3BwZXIiLCJpZCI6NjkyMjQ0LCJpYXQiOjE2NDgyNDgzNzF9.ZvCWdBhDS5sodrrmBcHH17i56TzTA46XPxjPxsAx2Ak",
"status": 200,
"statusText": "OK",
"headers": {
"content-length": "163",
"content-type": "application/json; charset=utf-8"
},
....} */
    /* {
        "data": {
            "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6ImNvbmRvbXNob3BwZXIiLCJpZCI6NjkyMjQ0LCJpYXQiOjE2NDg0MTQ4NTB9.jZOwWtr6SKpcByt_slmLRcZyVlLZS9-vrNy4dF5Hw5U",
            "username": "condomshopper"
        },
        "status": 200,
        "statusText": "OK",
        "headers": {
            "content-length": "206",
            "content-type": "application/json; charset=utf-8"
        },
    ...} */

    const initialValues = {

        email: "",
        password: "",

    };
    useEffect(() => {
        if (!isEmpty(userInfo)) {

            //response.data[0] ? navigate('/payment') : navigate('/location')
            setIsLoading(false)
            navigate(`/shipping/?auth_token=${userInfo.accessToken}&sl_id=${cartId}`)



            // navigate(redirect)

        }

    }, [userInfo])

    return (


        <>
            <Navigation>        </Navigation>
            <MainHeader></MainHeader>

            {isLoading ? <LoadingSpinner /> : (
                <>
                    <div className="form-container socialLogins">

                        <ul className="form-items">

                            <li>
                                <div className="googleContainer">
                                    <form id="signin-form">

                                        <button id='google-button' className="login-with-google-btn" type='button' onClick={googleSignIn}>
                                            {/* run result func, type button because the default is submit a form*/}
                                            Continue with Google
                                        </button>
                                        <button id='google-button' className="login-with-apple-btn" type='button' onClick={appleSignIn}>
                                            {/* run result func, type button because the default is submit a form*/}
                                            Continue with Apple
                                        </button>
                                        <button id='google-button' className="login-with-facebook-btn" type='button' onClick={facebookSignIn}>
                                            {/* run result func, type button because the default is submit a form*/}
                                            Continue with Facebook
                                        </button>
                                        <button id='google-button' className="login-with-an-btn" type='button' onClick={anSignIn}>
                                            {/* run result func, type button because the default is submit a form*/}
                                            Continue as a guest
                                        </button>


                                    </form>
                                </div>

                            </li>
                            <em>Social Logins: is a secure way to sign in by letting the service providers confirm to us that you are who you claim to be, without having to share with us sensetive information such as passwords. If you signed in, you can track your orders and see your order history and save your address so you dont have to put it again.  </em>

                        </ul>
                    </div></>
            )
            }

        </>


    )
}

export default SignIn;