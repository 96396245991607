import { Fragment, useContext,useEffect, useState } from 'react';
import { Outlet, Link, Navigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'

import './navigation.css';
import cart from '../../img/cart.png'
/* this will transform the cart img path to a specific src='' that webpack reads */
import logo from '../../img/logo.png'
import logo2 from '../../img/feehlaLogo.png'

import { useSelector, useDispatch } from "react-redux";
import { logoutAction } from '../../actions/userAction';
import Announcement from '../../components/Announcement/Announcement';

const Navigation = ({announcement}) => {
    let navigate = useNavigate()
    const dispatch = useDispatch()

    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    /* will get the state from anywhere */
    const user = useSelector((state) => state.user)
    const username = user.userInfo;
    const logout = () => {
        dispatch(logoutAction())
    }
    return (
        <Fragment>
            <div>
                <header className='banner'>
                    {/* its like a div but invisible from css */}
                    {announcement==='No'?'':(<Announcement></Announcement>)}
                        <div className='SiteHeader-container'>


                            {/*         	   <!------ amazon logo ------>
 */}
                            <a className="logo logo--left" href="https://feehla.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.19 216.72" width='40px' height='50px' fill='white'><path d="M92.13,100.05C67,139.27,42.28,177.74,17.26,216.72L0,205.7l91.72-143,94.47,140.35-17,11.43Z" /><path d="M68.27,23a23.05,23.05,0,1,1,46.1.22A23.05,23.05,0,1,1,68.27,23Z" /></svg>

                            </a>
                            <a className="breadcrumb__link back" href="https://feehla.com/">Go Back</a>

                        </div>
                </header>
                <Outlet />
                {/* like includes in ejs */}
            </div>
        </Fragment>
    );
};

export default Navigation;