/* first step of redux */
import axios from "axios";
import { signInWithGooglePopup,checkUser, signInWithFacebookPopup, signInWithAnPopup } from "../utils/firebase";
import { signInWithApplePopup } from "../utils/appleSignIn.js";

export const googleSignInAction = () => async (dispatch, getState) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */
  const googleResponse = await signInWithGooglePopup();

  //console.log(`google response ${JSON.stringify( googleResponse)}`)
  dispatch({ type: "GOOGLE_SIGNIN_REQUEST" });
  /* set the type tp product list request before the server request. which translates to loading and empty product array in reducer */
  axios
    .post("https://feehla-checkout-api.herokuapp.com/user/", { user: googleResponse.user }, {})
    .then((response) => {
      ////console.log(`from user action ${JSON.stringify(response.data)}`);

      dispatch({ type: "GOOGLE_SIGNIN_SUCCESS", payload: response.data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify(getState().user.userInfo)
      );
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "GOOGLE_SIGNIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
export const regularSignInAction = (data) => async (dispatch, getState) => {
  dispatch({ type: "REGULAR_SIGNIN_REQUEST" });

  axios
    .post("https://feehla-checkout-api.herokuapp.com/user/sign-in", data)
    .then((response) => {
      ////console.log("from SignInjsx ", response);
      if (!response.data.error) {
        dispatch({ type: "REGULAR_SIGNIN_SUCCESS", payload: response.data });
        localStorage.setItem(
          "userInfo",
          JSON.stringify(getState().user.userInfo)
        );
      }
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "REGULAR_SIGNIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: "LOGOUT" });
};
export const signInPlugAction = (authToken) => async (dispatch, getState) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */

  //console.log(`authToken caught ${JSON.stringify( authToken)}`)
  dispatch({ type: "SIGN_IN_PLUG_REQUEST" });
  axios.post("https://feehla-checkout-api.herokuapp.com/user/plug", { user: authToken },{})
    .then((response) => {
      ////console.log(`from user action ${JSON.stringify(response.data)}`);

      dispatch({ type: "SIGN_IN_PLUG_SUCCESS", payload: response.data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify(getState().user.userInfo)
      );
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "SIGN_IN_PLUG_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
export const appleSignInAction = () => async (dispatch, getState) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */
  const appleResponse = await signInWithApplePopup();

  //console.log(`apple response  ${JSON.stringify(appleResponse.user)}`)
  dispatch({ type: "GOOGLE_SIGNIN_REQUEST" });
  /* set the type tp product list request before the server request. which translates to loading and empty product array in reducer */
  axios
    .post("https://feehla-checkout-api.herokuapp.com/user/", { user: appleResponse.user }, {})
    .then((response) => {
      ////console.log(`from user action ${JSON.stringify(response.data)}`);

      dispatch({ type: "GOOGLE_SIGNIN_SUCCESS", payload: response.data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify(getState().user.userInfo)
      );
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "GOOGLE_SIGNIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
export const facebookSignInAction = () => async (dispatch, getState) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */
  const facebookResponse = await signInWithFacebookPopup();

  //console.log(`facebook response  ${JSON.stringify(facebookResponse.user)}`)
  dispatch({ type: "GOOGLE_SIGNIN_REQUEST" });
  /* set the type tp product list request before the server request. which translates to loading and empty product array in reducer */
  axios
    .post("https://feehla-checkout-api.herokuapp.com/user/", { user: facebookResponse.user }, {})
    .then((response) => {
      ////console.log(`from user action ${JSON.stringify(response.data)}`);

      dispatch({ type: "GOOGLE_SIGNIN_SUCCESS", payload: response.data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify(getState().user.userInfo)
      );
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "GOOGLE_SIGNIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
export const anSignInAction = () => async (dispatch, getState) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */
  const anResponse = await signInWithAnPopup();

  //console.log(`anynomus response  ${JSON.stringify(anResponse)}`)
  dispatch({ type: "GOOGLE_SIGNIN_REQUEST" });
  /* set the type tp product list request before the server request. which translates to loading and empty product array in reducer */
  axios
    .post("https://feehla-checkout-api.herokuapp.com/user/", { user: anResponse.user}, {})
    .then((response) => {
      ////console.log(`from user action ${JSON.stringify(response.data)}`);

      dispatch({ type: "GOOGLE_SIGNIN_SUCCESS", payload: response.data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify(getState().user.userInfo)
      );
    })
    .catch((error) => {
      ////console.log(`from useraction error ${JSON.stringify(error)}`);
      dispatch({
        type: "GOOGLE_SIGNIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    });
};
