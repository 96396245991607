import React from 'react';

import './product.css';
let img = '/images-300x300/1648388098-684.jpg'
let beg = 'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com'
let fimg = beg+img;
const ProductComponoent = ({ product }) => (

  <div className="productO">
    <div className="product-header">
      <svg fill="#f08804" viewBox="0,0,23,7" width="20vw"> <path d="M20,7 h-20 v-7 h23 z"></path>
      </svg>


      <p className="white-detail">Best Seller</p>
    </div>
    <div className="product-body">
      <div className="product-pic">
        {/*         <img src= {product.imageUrl} alt={product.title}/>
 */}   
{product.image.split('/')[0]!=='[aws_images_bucket]'? (<img src={'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/'+product.image.split('/images/')[1]} alt="" />)
:(<img src={'https://s3.us-east-2.amazonaws.com/feehla-images-uploads/images-300x300/'+product.image.split('/images/')[1]} alt="" />)

/* in database if starts with [aws_images_bucket] mean begin with us url bucket if it stars with [aws_images_bucket_bh] then bahrain url */
}
    </div>
      <div className="product-info">

        <div className="product-title">
          <p className="product-title-bold"  >
            {product.title}

          </p>

        </div>
        <div className="vendor">
          {product.vendor}
        </div>
        <div className="price">
          <h2 className="currency">BHD</h2>
          <h2 className="bd">{product.price.toString().split('.')[0]}</h2>
          <h2 className="fils">{typeof product.price.toString().split('.')[1] == 'undefined' ? '00' : product.price.toString().split('.')[1].slice(0, 1) + '0'}</h2>
        </div>
      </div>
    </div>
  </div>
);

export default ProductComponoent;