import { Fragment, useContext, useEffect, useState } from 'react';
import { Outlet, Link, Navigate } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'

import './SiteNavigation.css';
import cart from '../../img/cart.png'
/* this will transform the cart img path to a specific src='' that webpack reads */
import logo from '../../img/logo.png'
import logo2 from '../../img/feehlaLogo.png'

import { useSelector, useDispatch } from "react-redux";
import { logoutAction } from '../../actions/userAction';
import Announcement from '../../components/Announcement/Announcement';

const SiteNavigation = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()

    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    /* will get the state from anywhere */
    const user = useSelector((state) => state.user)
    const username = user.userInfo;
    const logout = () => {
        dispatch(logoutAction())
    }
    const [shClosed, setShClosed] = useState(false)
    const onClose = () => {
        setShClosed(true)
    }
    return (
        <Fragment>
            <div>
                <header className='banner'>
                    {/* its like a div but invisible from css */}
                    <Announcement></Announcement>
                    <div className="nav-top">

                        <div className='navLeft'>


                            {/*         	   <!------ amazon logo ------>
 */}
                            <a className="nav-logo" href="https://feehla.com/">
                                <img src={logo2} alt="" />

                            </a>
                        </div>
                        {/*              <!------ username ------>
 */}
                        <div className='navRight'>

                            <div className='userContainer'>


                                <div className="user-txt">

                                    {/*                                 {localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).username:'Sign-in'}

 */}
                                    {username ? (
                                        <NavDropdown title={username.username
                                        } id='username'>
                                            <LinkContainer to='/profile'>
                                                <NavDropdown.Item>Profile</NavDropdown.Item>
                                            </LinkContainer>
                                            <NavDropdown.Item onClick={logout}>
                                                Logout
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    ) : (
                                        <Link to={'/sign-in'}>

                                            <h3>
                                                Sign-in
                                            </h3>
                                        </Link>

                                    )}


                                </div>
                                {/*              <!------ user icon ------>
 */}

                                <div className="user">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186.19 216.72" width='17px' height='90px' fill='white'><path d="M92.13,100.05C67,139.27,42.28,177.74,17.26,216.72L0,205.7l91.72-143,94.47,140.35-17,11.43Z" /><path d="M68.27,23a23.05,23.05,0,1,1,46.1.22A23.05,23.05,0,1,1,68.27,23Z" /></svg>
                                </div>
                            </div>
                            {/*            	<!------ cart ------>
 */}
                            <Link to={"/main-cart"} className="cart-logo" >

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.98 243.82" width='17px' height="90px" fill='white'><path d="M123.48,243.82a28.36,28.36,0,0,0-3.22-1.09,23.12,23.12,0,1,1,28-24.32c1,11.32-7.06,21.93-18.49,24.34a30.1,30.1,0,0,0-3.25,1.07Z" /><path d="M62.49,243.82c-2.05-.59-4.12-1.12-6.14-1.8a23.11,23.11,0,0,1,9-45c11.61.6,20.78,10,21.66,22.12.79,10.75-7.33,21.16-18.3,23.54a26.71,26.71,0,0,0-3.18,1.12Z" /><path d="M164.91,0H185V173.32H0V.24H19.69c.12,2,.35,4,.35,6.12,0,47,.09,94-.08,141,0,4.82.89,6.58,6.22,6.55q66.48-.34,133,0c4.6,0,5.88-1.28,5.86-5.88-.16-47.16-.1-94.31-.1-141.47Z" /></svg>

                                {cartItems ? (
                                    <span className='cartCircle'>

                                        <p>
                                            {cartItems.length}
                                        </p>
                                    </span>

                                ) :
                                    (
                                        <p>
                                            0
                                        </p>

                                    )
                                }

                            </Link>
                        </div>
                    </div>
                </header>
                <Outlet />
                {/* like includes in ejs */}
            </div>
        </Fragment>
    );
};

export default SiteNavigation;