/* second step of redux */
export const userReducer = (state = {}, action) => {
    /* reducers are functions that take the actions process them and store the results in the store, and thevresults provided are the history of the products[] and the action of the new requested product */
    /* initial state is empty array for products, and action is to dispatch the payload to the state */
    switch (action.type) {
      /* check for the three action types 1 request to the server 2 response 3 error and do something with each type, it is set by hooks on productAction.js */
      case 'GOOGLE_SIGNIN_REQUEST':
        return { loading: true, userInfo: {} };
      //this case is set before the request to the backend, and we set the loading to true here and products to empty
      case 'GOOGLE_SIGNIN_SUCCESS':
        ////console.log(`from user Reducer success ${JSON.stringify(action.payload)}` );
        /* {"accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6Ik1vaGFtbWVkIEFsbm9haW1pIiwiaWQiOjQzMTM4LCJpYXQiOjE2NDkzNDIzOTZ9.2SaKUcYY3MvgqjYiy7sFnf9wmTNBwBpY-sGimLS2cSQ","username":"Mohammed Alnoaimi","id":43138,"email":"ne3emi@gmail.com"} */
        return { loading: false, userInfo: action.payload };
      // this case is set after we get success response. and we set the loading to false here and products to the response.data
      case 'GOOGLE_SIGNIN_FAIL':
        ////console.log(`from productReducer reject ${JSON.stringify(action.payload)}`);
  
        return { loading: false, error: [action.payload] };
      // this case is set after we get reject response. and we set the loading to false here and products to the error
      case 'REGULAR_SIGNIN_REQUEST':
        return { loading: true, userInfo: {} };
      //this case is set before the request to the backend, and we set the loading to true here and products to empty
      case 'REGULAR_SIGNIN_SUCCESS':
        ////console.log(`from user Reducer success ${JSON.stringify(action.payload)}`);
        /* {"accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6Ik1vaGFtbWVkIEFsbm9haW1pIiwiaWQiOjQzMTM4LCJpYXQiOjE2NDkzNDIzOTZ9.2SaKUcYY3MvgqjYiy7sFnf9wmTNBwBpY-sGimLS2cSQ","username":"Mohammed Alnoaimi","id":43138,"email":"ne3emi@gmail.com"} */
        return { loading: false, userInfo: action.payload };
      // this case is set after we get success response. and we set the loading to false here and products to the response.data
      case 'REGULAR_SIGNIN_FAIL':
        ////console.log(`from productReducer reject ${JSON.stringify(action.payload)}`);
  
        return { loading: false, error: [action.payload] };
      // this case is set after we get reject response. and we set the loading to false here and 
      case 'SIGN_IN_PLUG_REQUEST':
        return { loading: true, userInfo: {} };
      //this case is set before the request to the backend, and we set the loading to true here and products to empty
      case 'SIGN_IN_PLUG_SUCCESS':
        ////console.log(`from user Reducer success ${JSON.stringify(action.payload)}` );
        /* {"accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6Ik1vaGFtbWVkIEFsbm9haW1pIiwiaWQiOjQzMTM4LCJpYXQiOjE2NDkzNDIzOTZ9.2SaKUcYY3MvgqjYiy7sFnf9wmTNBwBpY-sGimLS2cSQ","username":"Mohammed Alnoaimi","id":43138,"email":"ne3emi@gmail.com"} */
        return { loading: false, userInfo: action.payload };
      // this case is set after we get success response. and we set the loading to false here and products to the response.data
      case 'SIGN_IN_PLUG_FAIL':
        ////console.log(`from productReducer reject ${JSON.stringify(action.payload)}`);
  
        return { loading: false, error: [action.payload] };

      case 'LOGOUT':
        return {};
      default:
        return state;
      /* default return the initial state */
    }
  };
  