import './signIn.css';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { googleSignInAction } from '../../actions/userAction'
import { useEffect } from 'react';
import axios from 'axios'
const GoogleAuth = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const { loading, error, userInfo } = user
    dispatch(googleSignInAction())

      useEffect(() => {


    }, [dispatch,userInfo])
  
    return (
user.userInfo?<h1>{user.username}</h1>:<h1>checking with google</h1>

    )
}

export default GoogleAuth;