import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../orderMsg/OrderMsg.css'
import { saveAddressAction, selectAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import { useEffect, useState } from "react";
import 'remixicon/fonts/remixicon.css'

import MapComponent from '../../components/mapSnippet/MapComponent';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../../components/Spinner/Spinner';
import { OrderStatus } from '../../components/orderStatus/OrderStatus';
import { bucketBuilder } from '../../utils/bucketBuilder';
const OrderMsg = () => {

    /* the listing will be similar to productsList */
    let navigate = useNavigate()
    let orderId = useParams().orderId.split('=')[1]
    let rand = useParams().rand
    const [order, setOrder] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const email_url = 'https://checkout.feehla.com/msg/' + rand + '/' + 'ORDER_ID=' + orderId;
    useEffect(() => {
        axios.get('https://feehla-checkout-api.herokuapp.com/checkout/msg', { params: { email_url: email_url } }).then((response) => {
            setOrder(response.data)
            setIsLoading(false)
            //console.log(`from use effect ${JSON.stringify(response.data)}`)
            /*             const {address_json}=order[0];
                        const address = JSON.parse(address_json)
            //console.log(`address json ${address.name}`)
             */
        })

    }, [])
    /*     //console.log(`order got from order msg front end${JSON.stringify(order)}`)
     */
    const getDate = (iso) => {
        var dt = new Date(iso * 1000);
        dt.setDate(dt.getDate() + 3)
        var expected = new Date(iso * 1000)
        expected.setDate(expected.getDate() + 1)

            .toString().slice(0, 10);
        const deliveryTime = `Expected delivery between \n ${expected.toString().slice(0, 10)} and ${dt.toString().slice(0, 10)}`
        return deliveryTime;
    }
    const getFullDate = (iso) => {
        var dt = new Date(iso * 1000);
        const orderDate = `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()} - ${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        return orderDate;
    }

    const getExpress = (iso) => {
        var dt = new Date(iso * 1000);
        dt.setHours(dt.getHours() + 2);
        const orderDate = `${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`

        const deliveryTime = `Expected before ${orderDate}`
        return deliveryTime;
    }
    const getAddress = (value) => {
        //console.log(`order state ${JSON.stringify(order)}`)
        const address = JSON.parse(order[0].address_json);
        //console.log(`lng ${address.location_latitude}`)
        return address;
    }
    const getProduct = (value) => {
        const products = JSON.parse(order[0].items_json);
        //console.log(`items json ${JSON.stringify(products)}`)
        return products;
    }


    return (

        <div >
            <>
                <Navigation announcement={'No'}>        </Navigation>
                {isLoading ? <LoadingSpinner></LoadingSpinner> : (

                    <div className='content'>
                        <Formik
                        >{() => (
                            /* we get those from formik props */
                            <Form className='wrap'>
                                <div className="main">
                                    <MainHeader announcement={'No'}></MainHeader>

                                    <div className='method'>
                                        {order ? (order.map((each, index) => (
                                            <>

                                                <div key={index} className="tableWrapper">
                                                    <table className='productTable'>
                                                        <tbody>

                                                            <tr key={index} >
                                                                <td >
                                                                    <div class="product-picS">

                                                                        <img src={each.thumb_url !== null ? (each.thumb_url.split('/')[0] !== 'uploads' ? ('https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/' + each.thumb_url.split('/images-300x300/')[1]) : ('https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/' + each.thumb_url.split('/images_thumbs/')[1])) : ''} alt="" />

                                                                        {/*                                                                         ////console.log(`https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/${each.thumb_url.split('/images_thumbs/')[1]}`)
 */}                                                                    </div>
                                                                </td>
                                                                <th className='productDescription'>
                                                                    <span className="product-titleT">

                                                                        <div>
                                                                            Order Id {each.id}
                                                                        </div>
                                                                    </span>
                                                                    {each.payment_status === 'success' ? <span className='paymentStatus'>Paid</span> : null}
                                                                    <span className='status'>{each.status==='driver_assigned'?'Captain '+each.driver_name+' assigned':each.status}</span>
                                                                    <span className='fullDate'>{getFullDate(each.time)}</span>
                                                                </th>
                                                                <td>
                                                                <a target="_blank" href={`https://wa.me/97336023021?text= I would like to enquire about order ${each.id} ${each.email_url}`}>
                                                                        <i class="fa-brands fa-whatsapp"></i>
                                                                    </a>

{/*                                                                     <div className='chevronR' >
                                                                        <span class="material-symbols-outlined">
                                                                            chevron_right
                                                                        </span>
                                                                    </div>
 */}                                                                </td>
                                                            </tr>
                                                            <div className='orderBreaker'></div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div key={index} className="tableWrapper">
                                                    <table className='productTable'>
                                                        <tbody>

                                                            <tr key={index} >
                                                                <td >
                                                                    {/*                                                                         ////console.log(`https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/${each.thumb_url.split('/images_thumbs/')[1]}`)
 */}
                                                                </td>
                                                                <th className='productDescription'>
                                                                    <span className="product-titleT">

                                                                        <Link to={``}>
                                                                            Delivery
                                                                        </Link>
                                                                    </span>
                                                                    {each.payment_status === 'success' ? <span className='paymentStatus'>Paid</span> : null}
                                                                    <span className='addressOrder'>{getAddress().name}</span>
                                                                    <span className='addressOrder'>{getAddress().area}</span>
                                                                    <span className='addressOrder'>{'H' + getAddress().building + ', ' + getAddress().road + ', ' + 'B' + getAddress().block}</span>
                                                                    <span className='addressOrder'>{getAddress().phone_number}</span>
                                                                    <span className='addressOrder'>{getAddress().memo ? getAddress().memo : ''}</span>

                                                                    <span className='time'>{each.delivery_option == 'express' ? getExpress(each.time) : getDate(each.time)}</span>
                                                                </th>
                                                                <td>
                                                                    <div className='chevronR' >
                                                                        <a target="_blank" href={getAddress().location_string ? getAddress().location_string : `https://maps.google.com/?q=${getAddress().location_latitude},${getAddress().location_longitude}`}>
                                                                        <i class="ri-map-pin-2-line"></i>                                                                       </a>
                                                                    </div>
                                                                    <div className='chevronR' >
                                                                        <a target="_blank" href={'https://wa.me/973' + getAddress().phone_number}>
                                                                            <i class="fa-brands fa-whatsapp"></i>
                                                                        </a>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='orderBreaker'></div>
                                                <OrderStatus order={order}></OrderStatus>

                                            </>
                                        ))) : <div>There are no orders</div>}

                                    </div>
                                    <aside className='orderDownbar'>
                                        <div className='order-summary__sections'>
                                            {order.length === 0 ? (
                                                <>
                                                    <div>No orders. </div>
                                                    <Link to="/">Go Shopping</Link>
                                                </>)

                                                :
                                                (getProduct().map((each, index) => {

                                                    /*                 <!------ each begins ------>
                                                     */
                                                    return (
                                                        <>
                                                            <div className="order-summary__section order-summary__section--product-list">
                                                                <table className="product-table">
                                                                    <tbody data-order-summary-section="line-eachs">
                                                                        <tr className="product">
                                                                            <td className="product__image">
                                                                                <div class="product-picS">
                                                                                  <img src={bucketBuilder(each.thumb_url?each.thumb_url:each.image)
                                                                                  }></img>

                                                                                    <span className='cartCircleS'>

                                                                                        <p>
                                                                                            {each.quantity}
                                                                                        </p>
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <th className="product__description" scope="row">

                                                                                <a href={`https://feehla.com//product/${each.id}/i`} className='product__description__name order-summary__emphasis'>
                                                                                    {each.name_en}
                                                                                </a>

                                                                            </th>
                                                                            <td class="product__price">

                                                                                <span className="order-summary__emphasis skeleton-while-loading">BHD</span>
                                                                                <span className="order-summary__emphasis skeleton-while-loading">{each.price}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>

                                                        </>
                                                    )
                                                })

                                                )}
                                            {order[0] ? (
                                                <>
                                                <div class="order-summary__section order-summary__section--total-lines" data-order-summary-section="payment-lines">
                                                    <table class="total-line-table">
                                                        <tbody class="total-line-table__tbody">
                                                            <tr class="total-line total-line--subtotal">
                                                                <th class="total-line__name" scope="row">Subtotal</th>
                                                                <td class="total-line__price">
                                                                    <span class="order-summary__emphasis skeleton-while-loading" data-checkout-subtotal-price-target="3200">
                                                                        BD {order[0].items_total}
                                                                    </span>
                                                                </td>
                                                            </tr>



                                                            <tr class="total-line total-line--shipping">
                                                                <th class="total-line__name" scope="row">
                                                                    <span>
                                                                        Shipping
                                                                    </span>

                                                                </th>
                                                                <td class="total-line__price">
                                                                    <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                                                        BD {order[0].shipping_cost}
                                                                    </span>
                                                                </td>
                                                            </tr>



                                                            <tr class="total-line total-line--taxes hidden" data-checkout-taxes="">
                                                                <th class="total-line__name" scope="row">
                                                                    Taxes
                                                                </th>
                                                                <td class="total-line__price">
                                                                    <span class="order-summary__emphasis skeleton-while-loading" data-checkout-total-taxes-target="0">BD0.00</span>
                                                                </td>
                                                            </tr>




                                                        </tbody>
                                                        <tfoot class="total-line-table__footer">
                                                            <tr class="total-line">
                                                                <th class="total-line__name payment-due-label" scope="row">
                                                                    <span class="payment-due-label__total">Total</span>
                                                                </th>
                                                                <td class="total-line__price payment-due" data-presentment-currency="USD">
                                                                    <span class="payment-due__currency remove-while-loading">BD</span>
                                                                    <span class="payment-due__price skeleton-while-loading--lg" data-checkout-payment-due-target="7199">
                                                                        {order[0].grand_price}
                                                                    </span>
                                                                </td>
                                                            </tr>

                                                        </tfoot>
                                                        <tbody class="total-line-table__tbody">


                                                            <tr class="total-line total-line--shipping">
                                                                <th class="total-line__name" scope="row">
                                                                    <span>
                                                                        Payment Method
                                                                    </span>

                                                                </th>
                                                                <td class="total-line__price">
                                                                    <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                                                        {order[0].payment_method == 'cash_on_delivery' ? 'Cash/Benefit' : 'Online Payment'}
                                                                    </span>
                                                                </td>
                                                            </tr>



                                                            <tr class="total-line total-line--taxes hidden" data-checkout-taxes="">
                                                                <th class="total-line__name" scope="row">
                                                                    Taxes
                                                                </th>
                                                                <td class="total-line__price">
                                                                    <span class="order-summary__emphasis skeleton-while-loading" data-checkout-total-taxes-target="0">BD0.00</span>
                                                                </td>
                                                            </tr>




                                                        </tbody>

                                                    </table>




                                                </div>
</>
                                            ) : (<div>No orders</div>)}


                                        </div>
                                    </aside>

                                </div>
                                <aside className='orderSidebar'>
                                    <div className='order-summary__sections'>
                                        {order.length === 0 ? (
                                            <>
                                                <div>No orders. </div>
                                                <Link to="https://feehla.com" target={'_blank'}>Go Shopping</Link>
                                            </>)

                                            :
                                            (getProduct().map((each, index) => {

                                                /*                 <!------ each begins ------>
                                                 */
                                                return (
                                                    <>
                                                        <div className="order-summary__section order-summary__section--product-list">
                                                            <table className="product-table">
                                                                <tbody data-order-summary-section="line-eachs">
                                                                    <tr className="product">
                                                                        <td className="product__image">
                                                                            <div class="product-picS">
                                                                            <img src={bucketBuilder(each.thumb_url?each.thumb_url:each.image)}></img>

                                                                                <span className='cartCircleS'>

                                                                                    <p>
                                                                                        {each.quantity}
                                                                                    </p>
                                                                                </span>

                                                                            </div>
                                                                        </td>

                                                                        <th className="product__description" scope="row">

                                                                            <a href={`https://feehla.com/product/${each.id}/i`}className='product__description__name order-summary__emphasis link'>
                                                                                {each.name_en}
                                                                            </a>

                                                                        </th>
                                                                        <td class="product__price">

                                                                            <span className="order-summary__emphasis skeleton-while-loading">BHD</span>
                                                                            <span className="order-summary__emphasis skeleton-while-loading">{each.price}</span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                        </div>

                                                    </>
                                                )
                                            })

                                            )}
                                        {order[0] ? (
                                            <div class="order-summary__section order-summary__section--total-lines" data-order-summary-section="payment-lines">
                                                <table class="total-line-table">
                                                    <tbody class="total-line-table__tbody">
                                                        <tr class="total-line total-line--subtotal">
                                                            <th class="total-line__name" scope="row">Subtotal</th>
                                                            <td class="total-line__price">
                                                                <span class="order-summary__emphasis skeleton-while-loading" data-checkout-subtotal-price-target="3200">
                                                                    BD {order[0].items_total}
                                                                </span>
                                                            </td>
                                                        </tr>



                                                        <tr class="total-line total-line--shipping">
                                                            <th class="total-line__name" scope="row">
                                                                <span>
                                                                    Shipping
                                                                </span>

                                                            </th>
                                                            <td class="total-line__price">
                                                                <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                                                    BD {order[0].shipping_cost}
                                                                </span>
                                                            </td>
                                                        </tr>



                                                        <tr class="total-line total-line--taxes hidden" data-checkout-taxes="">
                                                            <th class="total-line__name" scope="row">
                                                                Taxes
                                                            </th>
                                                            <td class="total-line__price">
                                                                <span class="order-summary__emphasis skeleton-while-loading" data-checkout-total-taxes-target="0">BD0.00</span>
                                                            </td>
                                                        </tr>




                                                    </tbody>
                                                    <tfoot class="total-line-table__footer">
                                                        <tr class="total-line">
                                                            <th class="total-line__name payment-due-label" scope="row">
                                                                <span class="payment-due-label__total">Total</span>
                                                            </th>
                                                            <td class="total-line__price payment-due" data-presentment-currency="USD">
                                                                <span class="payment-due__currency remove-while-loading">BD</span>
                                                                <span class="payment-due__price skeleton-while-loading--lg" data-checkout-payment-due-target="7199">
                                                                    {order[0].grand_price}
                                                                </span>
                                                            </td>
                                                        </tr>

                                                    </tfoot>
                                                    <tbody class="total-line-table__tbody">


                                                        <tr class="total-line total-line--shipping">
                                                            <th class="total-line__name" scope="row">
                                                                <span>
                                                                    Payment Method
                                                                </span>

                                                            </th>
                                                            <td class="total-line__price">
                                                                <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                                                    {order[0].payment_method == 'cash_on_delivery' ? 'Cash/Benefit' : 'Online Payment'}
                                                                </span>
                                                            </td>
                                                        </tr>



                                                        <tr class="total-line total-line--taxes hidden" data-checkout-taxes="">
                                                            <th class="total-line__name" scope="row">
                                                                Taxes
                                                            </th>
                                                            <td class="total-line__price">
                                                                <span class="order-summary__emphasis skeleton-while-loading" data-checkout-total-taxes-target="0">BD0.00</span>
                                                            </td>
                                                        </tr>




                                                    </tbody>

                                                </table>




                                            </div>

                                        ) : (<div>No orders</div>)}


                                    </div>
                                </aside>
                            </Form>
                        )}

                        </Formik>
                    </div>
                )}

            </>

        </div>
    );



}

export default OrderMsg;

