import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../payment/Payment.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createOrderAction, deductQtysAction } from '../../actions/orderActions';
import { useEffect, useState } from 'react';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import LoadingSpinner from '../../components/Spinner/Spinner';
import CouponCode from '../../components/couponCode/CouponCode';

const Payment = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    const [isLoading, setIsLoading] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(null);
    const [discountCode, setDiscountCode] = useState(null);
    const [editedItemsJsonAndTotalPerVendor, setEditedItemsJsonAndTotalPerVendor] = useState(null)

    const description = cartItems.map((item) => {
        return item.quantity + ' ' + item.name_en + ' @ BD ' + item.price
    }).join(' | ').toString()

    //////console.log(`cart items description ${JSON.stringify(description)}`)
    const { shippingMethod, shippingCharge } = addToCart.shippingMethod
    const { building, road, area, block } = addToCart.address
    // ////console.log(`address building${building}`)
    let time = Date.now() / 1000
    const user = useSelector((state) => state.user)
    const { id, email, username } = user.userInfo;
    let addressToAppend = addToCart.address
    let address = { ...addressToAppend, person_name: addressToAppend ? addressToAppend.name : username }
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)

    const initialValues = {
        paymentMethod: "cash_on_delivery"
    };
    const uniqueVendors = [...new Set(cartItems.map((item) => item.vendor_id))];
    const uniqueIds = [...new Set(cartItems.map((item) => item.vendor_id))];
    //console.log(uniqueIds);
    let shippingPerOrder = shippingCharge / uniqueVendors.length;
    let itemsJsonAndTotalPerVendor = uniqueVendors.map((vendor) => {
        const itemsJson = cartItems.filter((item) => item.vendor_id == vendor)
        return {
            itemsJson: itemsJson,
            itemsTotal: itemsJson.reduce((acc, item) => acc + Number(item.price) * item.quantity, 0)
        }
    })
    console.log('itemsJsonAndTotalPerVendor');
    console.dir(itemsJsonAndTotalPerVendor)

    const setAppliedCouponitemsJsonAndTotalPerVendor = (editedJson, discountAmount, couponCode) => {
        // itemsJsonAndTotalPerVendor = editedJson
        console.log('coupon applied itemsJsonAndTotalPerVendor');
        console.dir(itemsJsonAndTotalPerVendor)
        console.log('discount code ', couponCode);
        setDiscountAmount(discountAmount)
        setDiscountCode(couponCode)
        setEditedItemsJsonAndTotalPerVendor(editedJson)
    }

    const createOrder = useSelector((state) => state.createOrder)
    const { order, success, error } = createOrder
    const cartId = addToCart.cartId
    const accessToken = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).accessToken : ''

    const createOrders = (itemsJsonAndTotal, selectedValue) => {
        return ({
            vendor_id: itemsJsonAndTotal.itemsJson[0].vendor_id ? itemsJsonAndTotal.itemsJson[0].vendor_id : itemsJsonAndTotal.itemsJson[0].vendor.id,
            items_json: JSON.stringify(itemsJsonAndTotal.itemsJson),
            time: time,
            user_id: id,
            address_json: JSON.stringify(address),
            grand_price: shippingPerOrder + itemsJsonAndTotal.itemsTotal,
            payment_method: selectedValue,
            shipping_cost: shippingPerOrder,
            user_email: address?.email ? address.email : email,
            delivery_option: shippingMethod,
            remarks: address?.memo,
            items_total: itemsJsonAndTotal.itemsTotal,
            status: 'pending_merchant_response',
            payment_status: 'pending',
            coupon_text: discountCode ? discountCode : null
        })

    }

    const onPayment = (data) => {
        setIsLoading(true)

        if (data.paymentMethod == 'cash_on_delivery') {
            for (let itemsJsonAndTotal of editedItemsJsonAndTotalPerVendor ? editedItemsJsonAndTotalPerVendor : itemsJsonAndTotalPerVendor) {
                const order = createOrders(itemsJsonAndTotal, data.paymentMethod)
                console.log('order to create');
                console.dir(order)
                dispatch(createOrderAction(order))
            }
            setIsLoading(false)

        } else if (shippingCharge == 4.4 || (uniqueIds.indexOf(88) == -1 && uniqueIds.indexOf(130) == -1 && uniqueIds.indexOf(164) == -1 && uniqueIds.indexOf(519) == -1 && uniqueIds.indexOf(523) == -1 && uniqueIds.indexOf(520) == -1 && uniqueIds.indexOf(522) == -1 && uniqueIds.indexOf(135) == -1)) {
            for (let itemsJsonAndTotal of editedItemsJsonAndTotalPerVendor ? editedItemsJsonAndTotalPerVendor : itemsJsonAndTotalPerVendor) {
                const order = createOrders(itemsJsonAndTotal, data.paymentMethod)
                console.log('order to create');
                dispatch(createOrderAction(order))
            }
            setIsLoading(false)

        } else {
            // let description=cartItems[0].title
            //console.log(`we should reach here for al nashmi ${data.paymentMethod}`)
            axios.post('https://feehla-checkout-api.herokuapp.com/checkout/pay-order', { grand_price: itemsTotalPrice + shippingCharge, email: email, username: username, description: description }).then(async function (response) {
                //////console.log(`catched create pay response from frontend ${JSON.stringify(response)}`);
                if (response.data['error-code'] == 0) {
                    /* because the key is string so this is intead of [0] */
                    //////console.log('should navigate');
                    /* navigate('/sadad-create')*/
                    localStorage.setItem('transId', response.data["transaction-reference"])
                    localStorage.setItem('userInfo', JSON.stringify(user.userInfo))
                    localStorage.setItem('cartItems', JSON.stringify(cartItems))
                    localStorage.setItem('shippingMethod', JSON.stringify(addToCart.shippingMethod))

                    window.location.href = response.data['payment-url'];
                    //  setIsLoading(false)

                    /* this is to redirect to external url */
                } else {
                    //////console.log(response.data);
                }
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false)

            });
        }

        dispatch(deductQtysAction(cartItems))
    }
    useEffect(() => {
        if (success) {
            navigate(`/thank-you`)
            /* save the orders in the state to get them from this url */
        }
    }, [navigate, success])

    return (
        <>
            <Navigation>        </Navigation>
            <OrderSummary discountAmount={discountAmount} discountCode={discountCode}></OrderSummary>

            {isLoading ? <LoadingSpinner /> : (
                <div className='content'>
                    <div className='wrap'>
                        <div className="main">
                            <MainHeader></MainHeader>


                            <CheckoutSteps step1 step2 step3 cartId={cartId}></CheckoutSteps>

                            <div className='method'>
                                <div role={'table'} className='contentFieldS'>
                                    <div role={'row'} className='contentRowS'>
                                        <div className='radioWrapper'>
                                            <div className='radioLabel'>
                                                <div role={'rowheader'} className='addressHeader'>Ship to
                                                </div>
                                                <div role={'cell'} className='addressBody'>{building} {road} {area} {block}
                                                </div>
                                            </div>
                                            <Link to={"/select-address"}>
                                                <span className='change'> Change</span>
                                            </Link>

                                        </div>
                                    </div>
                                    <div role={'row'} className='contentRowS'>
                                        <div className='radioWrapper'>
                                            <div className='radioLabel'>
                                                <div role={'rowheader'} className='addressHeader'>Method
                                                </div>
                                                <div role={'cell'} className='addressBody'>
                                                    {shippingMethod == 'standard' ? 'Normal Shipping 1-3 days' : shippingMethod == 'express' ? 'Express Shipping 2 hours' : 'International'} - BD {shippingCharge}
                                                </div>
                                            </div>
                                            <Link to={`/shipping/?auth_token=${accessToken}&sl_id=${cartId}`}>
                                                <span className='change'> Change</span>
                                            </Link>

                                        </div>
                                    </div>

                                </div>

                                <CouponCode
                                    itemsTotalPrice={itemsTotalPrice}
                                    itemsJsonAndTotalPerVendor={itemsJsonAndTotalPerVendor}
                                    setAppliedCouponitemsJsonAndTotalPerVendor={(x, y, z) => setAppliedCouponitemsJsonAndTotalPerVendor(x, y, z)}
                                ></CouponCode>
                                {/* Formik beginning */}

                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onPayment}
                                >{({ values }) => (


                                    <Form>
                                        <div className='methodP'>
                                            <h2>Payment</h2>
                                            <div id="my-radio-group">{/* {JSON.stringify(cartItems)} */}</div>
                                            <div role="group" aria-labelledby="my-radio-group" className='contentField'>
                                                <div className='contentRow'>
                                                    <label className='radioWrapper'>
                                                        <div className='radioInput'>
                                                            <Field className='radioButton' type="radio" name="paymentMethod" value="cash_on_delivery"
                                                                disabled={shippingCharge === 4.4}

                                                            />
                                                        </div>
                                                        <div className='radioLabel'>
                                                            <span className='radioDays'>Cash/Benefit on delivery {shippingCharge === 4.4 ? <small>(Not Available for International)</small> : ''}
                                                            </span><span className='radioAccessory'></span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className='contentRow'>
                                                    <label className='radioWrapper'>
                                                        <div className='radioInput'>
                                                            <Field className='radioButton' type="radio" name="paymentMethod" value="online_payment" />
                                                        </div>
                                                        <div className='radioLabel'>
                                                            <span className='radioSadad'>

                                                                <img src={sadad}></img>
                                                            </span>
                                                            <span className='radioP'>Online Payment</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end formik */}
                                        <div className='stepFooter'>

                                            <button className='stepButton' type="submit" disabled={isLoading}><span>Confirm Order</span></button>
                                        </div>
                                    </Form>
                                )
                                    }
                                </Formik>

                            </div>
                        </div>
                        <SideBarSummary discountAmount={discountAmount} discountCode={discountCode}></SideBarSummary>




                    </div>
                </div>
            )
            }
        </>
    );



}

export default Payment;