import React from 'react';
import './Location.css';
import axios from 'axios';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import { locationAction } from "../../actions/addToCartAction";
import { Link, useNavigate } from 'react-router-dom'
import mapStyles from '../../components/mapStyles';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';

Geocode.setApiKey('AIzaSyDk7GtS43u9ujKnI4XrG-uUGWoFUCWToOs')
Geocode.enableDebug();

const Location = (props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [marker, setMarker] = React.useState({

        lat: 26.066700,
        lng: 50.557701,
        city: '',
        road: ''
    });
    const [formValues, setFormValues] = React.useState(null);
    const getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].address_components[0].long_name;
                        return area;
                    }
                }
            }
        }
    };
    const getRoad = (addressArray) => {
        let road = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
                road = addressArray[i].address_components[0].long_name;
                return road;
            }
        }
    };
    const onMapClick = React.useCallback(
        /* useCallback will not let the page rerender everytime a new setMarker is fired */
        (event) => {
            /* set the marker state from the click event lng and lat */
            ////console.log(event)
            setMarker({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })

            panTo({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            })
            Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then((response) => {
                const addressArray = response.results
                const road = getRoad(addressArray)
                const area = getArea(addressArray)
                // const city = response.results[1].formatted_address.split(',')[1]
                //types>locality
                // const road = response.results[1].formatted_address.split(',')[0]
                //types>route
                ////console.log('from addressjsx city road', area, road);
                setMarker({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    area: area ? area : '',
                    road: road ? road : ''
                })
                panTo({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                })
                ////console.log('from addressjsx geocode ', response)
            }).catch()

            /* the problem here is the map gets rerendered and go to the center*/
        },
        []
    )
    const mapRef = React.useRef()
    const onMapLoad = React.useCallback(
        /* useCallback will not run on every rerender to save resources*/
        (map) => {
            mapRef.current = map;
            navigator.geolocation.watchPosition(function (position) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        ////console.log(`from Address default position ${JSON.stringify(position.coords.longitude)}`)
                        setMarker({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        })
                        locatePanTo({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        })
                        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then((response) => {
                            const addressArray = response.results
                            const road = getRoad(addressArray)
                            const area = getArea(addressArray)
                            // const city = response.results[1].formatted_address.split(',')[1]
                            //types>locality
                            // const road = response.results[1].formatted_address.split(',')[0]
                            //types>route
                            ////console.log('from addressjsx city road', area, road);
                            setMarker({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                                area: area ? area : '',
                                road: road ? road : ''
                            })
                            panTo({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            })
                            ////console.log('from addressjsx geocode ', response)
                        }).catch()

                    },
                    () => null
                );

            },
                function (error) {
                    if (error.code == error.PERMISSION_DENIED)
                        //////console.log("you denied me :-(");
                        alert('Please enable location tracking from your browser to let us store your location to the driver and later you can disable it, otherwise you can copy and paste your location url in the field below')
                });
        },
        []
    )

    const libraries = ['places']
    const mapContainerStyle = {
        width: '100vw',
        height: '81vh',
    }
    const center = {
        lat: 26.066700,
        lng: 50.557701,
    }
    const options = {
        disableDefaultUI: true,
        zoomControl: false,
        fullscreenControl: true,
        styles: mapStyles,

    }
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        //mapRef.current.setZoom(14);
    }, []);
    const locatePanTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(15);
    }, []);


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDk7GtS43u9ujKnI4XrG-uUGWoFUCWToOs",
        libraries
        /* first we load the script with the apikey and enabled libraries, we put it outside to avoid rerenders, and it will give us back isloaded or error */
    })

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";
    /* else if loaded continue tye function and render it */
    function Locate({ panTo }) {
        return (
            <button
                className="locatef"
                onClick={() => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            ////console.log(`from Address position ${JSON.stringify(position.coords.longitude)}`)
                            panTo({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            });
                        },
                        () => null
                    );
                }}
            >
                <span class="material-icons-outlined compass">
                    my_location
                </span>
            </button>
        );
    }


    return (
        <>

            <Navigation>        </Navigation>
            <MainHeader></MainHeader>

            <div className='googleMap'>
                <Locate panTo={locatePanTo} />
                <div className="direction">
                    <div className='mapTitle'>Click on the map to move the pin to your exact location
                        {/*                 <span role='img' aria-label='delivery'>  🚀</span>
 */}                </div>
                </div>
                <GoogleMap mapContainerStyle={mapContainerStyle} zoom={11} center={center} options={options}
                    onClick={onMapClick}
                    onLoad={onMapLoad}
                /* we will store the event in useRef hook so we dont rerender everytime we move the map */
                >
                    <Marker
                        position={{
                            lat: marker.lat,
                            lng: marker.lng
                        }}
                        /* we get this from the state */
                        icon={{
                            url: `/user.svg`,
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(25, 50),
                            scaledSize: new window.google.maps.Size(50, 50),
                        }}
                    />

                    <button className="loa" onClick={() => {
                        dispatch(locationAction(marker))
                        navigate('/address')
                    }}><span> Confirm</span></button>

                </GoogleMap>
            </div>


        </>

    )
}



export default Location;