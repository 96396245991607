/* create order from state */
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../paymentSuccess/PaymentSuccess.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createOrderAction } from '../../actions/orderActions';
import { useEffect } from 'react';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import '../thankYou/thankYou.css'

const PaymentSuccessDashboard = () => {
    let orderId = useParams().orderId
    let transactionId = useParams().transactionId

    return (

        < >
            <Navigation announcement={'No'}>        </Navigation>
            <MainHeader></MainHeader>
            <div className='wrap'>
                <div className="main">
                    <div className='method'>
                        <h1 className='thankyouMsg'>Your payment was successful! </h1>
                        <p className='preparing'>
                        Your transaction ID is {transactionId} and your order ID is {orderId}. We appreciate your business! 
                        </p>
                    </div>
                </div>
            </div>

        </>
    );



}

export default PaymentSuccessDashboard;