import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './AnnouncementDesktop.css'

function AnnouncementDesktop() {
    const [shClosed2, setShClosed2] = useState(false)
    const onClose2 = () => {
        setShClosed2(true)
    }

    return (
        <>
            <div class={shClosed2 ? "SiteHeader-notice_bar-Desktop SiteHeader-notice_bar-Desktop__closed" : "SiteHeader-notice_bar-Desktop"}>
                <div class="SiteHeader-notice">
                    <p class="SiteHeader-notice-text">
                        Free shipping on orders over BD<span class="threshold">15</span> <span class="amount"></span>
                    </p>
                    <span onClick={onClose2} class="material-symbols-outlined close-notice-bar">
                        close
                    </span>                        </div>
            </div>
        </>
    )
}

export default AnnouncementDesktop