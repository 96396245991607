import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import './EditAddress.css';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import { saveAddressAction } from '../../actions/addToCartAction';
import { useNavigate } from 'react-router-dom';
import mapStyles from '../../components/mapStyles';
import Navigation from '../navigation/navigation';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import MainHeader from '../mainHeader/MainHeader';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';

const Address = (props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDk7GtS43u9ujKnI4XrG-uUGWoFUCWToOs",

        /* first we load the script with the apikey and enabled libraries, we put it outside to avoid rerenders, and it will give us back isloaded or error */
    })

    const locationData = useSelector((state) => {
        return state.addToCart;
    })
    /* select the product state from the store */
    const { location } = locationData
    ////console.log('from addressjx ', location);
    const user = useSelector((state) => state.user)
    const { username, email, id } = user.userInfo;
    const addToCart = useSelector((state) => state.addToCart)
    const { address } = addToCart
    const cartId = addToCart.cartId
    //const [formValues, setFormValues] = React.useState(null);
    //setFormValues(location);
    const mapContainerStyle = {
/*         width: '86vw',
 */        height: '20vh',
        margin: '7vh 0 0 0',
    }
    const center = {
        lat: location.lat,
        lng: location.lng
    }
    const options = {
        disableDefaultUI: true,
        styles: mapStyles,
    }

    const onSubmit = (value) => {
        /* because this function is extracted from inside on submit handler which will return a value in the arg, which is beautifully in formik the object of the form values */
        ////console.log(`from addressjsx ${JSON.stringify(value)}`)
        /* from addressjsx {"address":"85 r 4601 b 746","city":"Aali","location":"xnxx.com","phone_number":32220169} */
        dispatch(saveAddressAction(value))
        axios.post('https://feehla-checkout-api.herokuapp.com/checkout/edit-address', { address: { ...value, addressId: address.id,user_id: id ? id: 0 }},).then(() => {
            /* in the post request, first arg is the backend url, second is what we pass in the body, third is the header, post is when we want to write something to the database and get is when we want to get something from the database */

            ////console.log('worked')
        }).catch((err) => { console.log(err) })
        navigate('/payment')
    }

    let initialValues = {
        title: '',
        name: '',
        email: '',
        building: '',
        road: '',
        block: '',
        area: '',
        location: '',
        phone_number: '',
        lat: '',
        lng: '',
        memo: ''
    }
    const loadAddress = {
        title: address.title,
        name: username,
        email: email,
        building: address.building,
        road: location.road,
        block: address.block,
        area: location.area,
        location: `https://maps.google.com/?q=${location.lat},${location.lng}` || '',
        phone_number: address.phone_number,
        lat: location.lat,
        lng: location.lng,
        memo: address.memo,

    }
    const validate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Invalid email address';

        }

        if (!values.building || values.building == '') {
            errors.building = 'Required';

        }
        if (!values.name) {
            errors.name = 'Required';
        }
        if (!values.road) {
            errors.road = 'Required';

        }
        if (!values.area) {
            errors.area = 'Required';
        }
        if (!values.location) {
            errors.location = 'Please paste your google maps location url here';
        }
        if (!/[+-\d\s]{4,}$/.test(values.phone_number)) {
            errors.phone_number = 'invalid';
        }

        //...
        ////console.log('errors: ', JSON.stringify(errors));
        ////console.log('values: ', JSON.stringify(values));

        return errors;
    };
    return (
        <>
            <Navigation>        </Navigation>
            <OrderSummary></OrderSummary>

            <div className="content">
                <Formik validate={validate} onSubmit={onSubmit} initialValues={loadAddress || initialValues} enableReinitialize>
                    {({ values }) => {
                        /* props.values is same as use state and renders on change every letter is entered is setState, and we grab the state from props.values, */
                        {/* if form values are there use them otherwise use initial values */ }
                        return (
                            <Form className='wrap' id="shipping-form" method='post' action='/payment'>
                                <div className="main">
                                    <MainHeader></MainHeader>

                                    <CheckoutSteps step1 step2 cartId={cartId}></CheckoutSteps>


                                    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={17} center={center} options={options}
                                    /* https://blog.logrocket.com/integrating-google-maps-react/ */
                                    /* we will store the event in useRef hook so we dont rerender everytime we move the map */
                                    >
                                        <Marker
                                            position={{
                                                lat: location.lat,
                                                lng: location.lng
                                            }}
                                            /* we get this from the state */
                                            icon={{
                                                url: `/user.svg`,
                                                origin: new window.google.maps.Point(0, 0),
                                                anchor: new window.google.maps.Point(20, 20),
                                                scaledSize: new window.google.maps.Size(40, 40),
                                            }}

                                        />

                                    </GoogleMap>
                                    <ul className="form-itemsA">
                                        <li>
                                            <h2>Shipping Address</h2>
                                        </li>
                                        <li>
                                            <label >Address Title</label>
                                            <Field type="text" name="title" id="title" placeholder='Work/Home...(optional)' className='clean' />
                                        </li >
                                        <li>
                                            <label >Name </label>                         {!values.name ? <Field className='dirty' type="text" name="name" id="name" /> : <Field className='clean' type="text" name="name" id="name" />}

                                        </li>
                                        <li>
                                            <label >Email </label>
                                            {!values.email ? <Field className='dirty' type="text" name="email" id="email" /> : <Field className='clean' type="text" name="email" id="email" />}
                                            <ErrorMessage name='email' component='p'></ErrorMessage>

                                        </li>
                                        <li>

                                            <label >Building </label>
                                            {!values.building ? <Field className='dirty' type="text" name="building" id="building" /> : <Field className='clean' type="text" name="building" id="building" />}
                                            <ErrorMessage name='building' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Road </label>
                                            {!values.road ? <Field className='dirty' type="text" name="road" id="road" /> : <Field className='clean' type="text" name="road" id="road" />}
                                            <ErrorMessage name='road' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Block </label>

                                            {!values.block ?
                                                <Field className='dirty' type="text" name="block" id="block" />
                                                : <Field className='clean' type="text" name="block" id="block" />}
                                            <ErrorMessage name='block' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >phone_number</label>
                                            {!values.phone_number ? <Field className='dirty' type="text" name="phone_number" id="phone_number" /> : <Field className='clean' type="text" name="phone_number" id="phone_number" />}
                                            <ErrorMessage name='phone_number' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Additional Directions</label>
                                            <Field type="text" name="memo" id="memo" placeholder='(optional)' className='clean' />
                                        </li>

                                        <li>
                                            <label >Area </label>
                                            {!values.area ? <Field className='dirty' type="text" name="area" id="area" /> : <Field className='clean' type="text" name="area" id="area" />}
                                            <ErrorMessage name='area' component='p'></ErrorMessage>

                                        </li>

                                        <li>
                                            <label >Location url</label>
                                            {!values.location ? <Field className='dirty' type="text" name="location" id="location" /> : <Field className='clean' type="text" name="location" id="location" />}
                                        </li>

                                        <li>
                                            <button type='submit' className="primary">Continue</button>
                                        </li>
                                    </ul>
                                </div>
                                <SideBarSummary></SideBarSummary>


                            </Form>
                        )
                    }}

                </Formik>
            </div>
        </>

    )
}



export default Address;