/* create order from state */
import axios from 'axios'
import '../paymentSuccess/PaymentSuccess.css'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import '../thankYou/thankYou.css'

const PaymentDashboard = () => {
    let orderId = useParams().orderId
    // alert(orderId)

    const onPay = async () => {
        const response = await axios.post('https://feehla-checkout-api.herokuapp.com/checkout/pay-order-driver',
            {
                orderId: orderId,
               
            })
            console.log(response);

        if (response.data['error-code'] == 0) {
            window.location.href = response.data['payment-url'];
        } else {
            alert(`There is some problem with generating the payment link`)
        }
    };


    useEffect(() => {
        onPay()

    }, [])






    return (
        < >
        <Navigation announcement={'No'}>        </Navigation>
        <MainHeader></MainHeader>
        <div className='wrap'>
            <div className="main">
                <div className='method'>
                    <h1 className='thankyouMsg'>You will be redirected to the payment link shortly ...</h1>
                </div>
            </div>
        </div>

    </>


);



}

export default PaymentDashboard;