
export const bucketBuilder = (imgUrl) => imgUrl ?imgUrl.split('/')[0] === '[aws_images_bucket_bh]'?(`https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/${imageSizes(imgUrl)}/` + getStringAfterFirstSlash(imgUrl)):imgUrl.split('/')[0] === '[aws_images_bucket]' ? (`https://s3.us-east-2.amazonaws.com/feehla-images-uploads/${imageSizes(imgUrl)}/` + imgUrl.split('/').pop()):imgUrl.split('/')[0] === 'https:'?imgUrl:(`https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/${imageSizes(imgUrl)}/`+getStringAfterFirstSlash(imgUrl)) : ''

const imageSizes=(imgUrl) => {
    const thePart = imgUrl.split('/')[1]
    return thePart
}
const getStringAfterFirstSlash=(str)=> {
    const arr = str.split('/')
//console.log(`arr ${arr}`)
    // Use splice() to remove the second element ("is") from the array
    arr.splice(0, 2);
   // console.log(`arr ${arr}`)

    // Use join() to concatenate the elements of the array into a single string, separated by spaces
    const strr = arr.join("/");
    // console.log(`strr ${strr}`)

    return strr; // expected output: "This an array"
      }