import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../payment/Payment.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createAppOrderAction, createOrderAction, deductQtysAction } from '../../actions/orderActions';
import { useEffect, useState } from 'react';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import LoadingSpinner from '../../components/Spinner/Spinner';

const AppPayment = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    const [isLoading, setIsLoading] = useState(false);
    const description = cartItems.map((item) => {

        return item.quantity + ' ' + item.name_en + ' @ BD ' + item.price
    }).join(' | ').toString()
    //////console.log(`cart items description ${JSON.stringify(description)}`)
    const { shippingMethod, shippingCharge } = addToCart.shippingMethod
    const { building, road, area, block } = addToCart.address
    // ////console.log(`address building${building}`)
    let time = Date.now() / 1000
    const user = useSelector((state) => state.user)
    let id
    if (user.userInfo) {
        id = user.userInfo.id;

    }
    const user_id = id || ''
    let address = addToCart.address
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const initialValues = {
        paymentMethod: "cash_on_delivery"
    };
    const uniqueVendors = [...
        new Set(cartItems.map(
            (item) => {
                ////console.log(`from shippingjsx each vendor ${item.vendor}`)
                return item.vendor
            })
        )];
    const uniqueIds = [...
        new Set(cartItems.map(
            (item) => {
                //////console.log(`from shippingjsx each vendor ${item.vendor}`)
                return item.vendor_id
            })
        )];
    //console.log(uniqueIds);
    let shippingPerOrder = shippingCharge / uniqueVendors.length;
    const createOrder = useSelector((state) => state.createOrder)
    const { order, success, error } = createOrder
    const cartId = addToCart.cartId
    const accessToken = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).accessToken : ''

    useEffect(() => {
        if (success) {
            const orders = order[0].map((x) => x.id)
            console.log(`${orders}orders to url ${JSON.stringify(orders.toString())}`)
            navigate(`/app/thank-you/?order_id=${orders.toString()}`)
            /* save the orders in the state to get them from this url */
        }
        // eslint-disable-next-line
    }, [navigate, success])

    const onPayment = (data) => {
        setIsLoading(true)
        if (data.paymentMethod == 'cash_on_delivery') {
            //console.log(`online shouldnt hit here ${data.paymentMethod}`)

            let perVendor = uniqueVendors.map((vendor) => {
                return cartItems.filter((item) => item.vendor == vendor)

            })
            let concatObjects = [];

            console.log(`per vendor items${JSON.stringify(perVendor)}`)
            perVendor.map((order) => {
                let itemsJson = []
               // console.log(`order per verndor ${JSON.stringify(order)}`)
                /* order [{"id":172844,"title":"Turkish coffee set tropic 6 pcs","price":49.5,"stock":2,"image":"[aws_images_bucket_bh]/images/1589621356-826.jpg","vendor":"Oda Decor ","qty":1}] */
                const itemsTotalPerVendor = order.reduce((acc, item) => acc + item.price * item.quantity, 0)
                const itemsDetsPerVendor = order.reduce((acc, item) => acc + item, {})
                // ////console.log(`item dets ${JSON.stringify(itemsDetsPerVendor)}`)
                itemsJson.push(order)
                let finalFilter = itemsJson.filter((item) => item.vendor_id == order.vendor_id)

                concatObjects.push({
                    vendor_id: order[0].vendor_id,
                    items_json: JSON.stringify(finalFilter[0]),
                    time: time,
                    user_id: user_id,
                    address_json: JSON.stringify(address),
                    grand_price: shippingPerOrder + itemsTotalPerVendor,
                    payment_method: data.paymentMethod,
                    shipping_cost: shippingPerOrder,
                    user_email: address.email,
                    delivery_option: shippingMethod,
                    remarks: address.memo,
                    items_total: itemsTotalPerVendor,
                    status: 'pending_merchant_response',
                    payment_method: 'cash_on_delivery',
                    payment_status: 'pending',

                });
                // ////console.log(`final filter ${JSON.stringify(JSON.stringify(finalFilter))}`)


                /*                 const asArray = Object.entries(order);
                                const filtered = asArray.filter(([key, value]) => value === order[0].vendor);
                                const justStrings = Object.fromEntries(filtered);
                                ////console.log(`just strings ${JSON.stringify(justStrings)}`)
                                ////console.log(`filtered  ${JSON.stringify(filtered)}`)
                 */


                /*                 Object.filter = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key]) ) .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            
                            var itemsFiltered = Object.filter(cartItems, item => item.vendor_id == order.vendor_id); 
                 */

                const orderPerVendor = {
                    vendor_id: order[0].vendor_id,
                    items_json: order[0],
                    time: time,
                    user_id: user_id,
                    address_json: JSON.stringify(address),
                    grand_price: shippingPerOrder + itemsTotalPerVendor,
                    payment_method: data.paymentMethod,
                    shipping_cost: shippingPerOrder,
                    user_email: address.email,
                    delivery_option: shippingMethod,
                    remarks: address.memo,
                    items_total: itemsTotalPerVendor,

                }
               // console.log(`order per vendor ${orderPerVendor}`)


            }
            )
           // console.log(`concat obj${JSON.stringify(concatObjects)}`)

            dispatch(createAppOrderAction(concatObjects))
            setIsLoading(false)

            // alert(data.paymentMethod)


            /*             || order[0].vendor_id!=88 || order[0].vendor_id!=130 || order[0].vendor_id!=164 || order[0].vendor_id!=519 || order[0].vendor_id!=523 || order[0].vendor_id!=520|| order[0].vendor_id!=522 || order[0].vendor_id!=135
             */
        } else if (shippingCharge == 4.4 || (uniqueIds.indexOf(88) == -1 && uniqueIds.indexOf(130) == -1 && uniqueIds.indexOf(164) == -1 && uniqueIds.indexOf(519) == -1 && uniqueIds.indexOf(523) == -1 && uniqueIds.indexOf(520) == -1 && uniqueIds.indexOf(522) == -1 && uniqueIds.indexOf(135) == -1)) {
            //console.log(`al nashmi shouldnt hit here ${data.paymentMethod}`)

            let perVendor = uniqueVendors.map((vendor) => {
                return cartItems.filter((item) => item.vendor == vendor)

            })
            ////console.log(`per vendor items${JSON.stringify(perVendor)}`)
            perVendor.map((order) => {
                let concatObjects = [];

                ////console.log(`order per verndor ${JSON.stringify(order[1])}`)
                /* order [{"user_id":172844,"title":"Turkish coffee set tropic 6 pcs","price":49.5,"stock":2,"image":"[aws_images_bucket_bh]/images/1589621356-826.jpg","vendor":"Oda Decor ","qty":1}] */
                const itemsTotalPerVendor = order.reduce((acc, item) => acc + item.price * item.quantity, 0)
                const itemsDetsPerVendor = order.reduce((acc, item) => acc + item, {})
                // ////console.log(`item dets ${JSON.stringify(itemsDetsPerVendor)}`)
                concatObjects.push(order);
                let finalFilter = concatObjects.filter((item) => item.vendor_id == order.vendor_id)
                // ////console.log(`concat obj${JSON.stringify(concatObjects)}`)
                // ////console.log(`final filter ${JSON.stringify(JSON.stringify(finalFilter))}`)


                /*                 const asArray = Object.entries(order);
                                const filtered = asArray.filter(([key, value]) => value === order[0].vendor);
                                const justStrings = Object.fromEntries(filtered);
                                ////console.log(`just strings ${JSON.stringify(justStrings)}`)
                                ////console.log(`filtered  ${JSON.stringify(filtered)}`)
                 */


                /*                 Object.filter = (obj, predicate) => Object.keys(obj).filter( key => predicate(obj[key]) ) .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            
                            var itemsFiltered = Object.filter(cartItems, item => item.vendor_id == order.vendor_id); 
                 */
                const orderPerVendor = {
                    vendor_id: order[0].vendor_id,
                    items_json: order[0],
                    time: time,
                    user_id: user_id,
                    address_json: JSON.stringify(address),
                    grand_price: shippingPerOrder + itemsTotalPerVendor,
                    payment_method: data.paymentMethod,
                    shipping_cost: shippingPerOrder,
                    user_email: address.email,
                    delivery_option: shippingMethod,
                    remarks: address.memo,
                    items_total: itemsTotalPerVendor,

                }
                //////console.log(`order per vendor ${orderPerVendor}`)

                dispatch(createOrderAction({
                    vendor_id: order[0].vendor_id,
                    items_json: JSON.stringify(finalFilter[0]),
                    time: time,
                    user_id: user_id,
                    address_json: JSON.stringify(address),
                    grand_price: shippingPerOrder + itemsTotalPerVendor,
                    payment_method: data.paymentMethod,
                    shipping_cost: shippingPerOrder,
                    user_email: address.email,
                    delivery_option: shippingMethod,
                    remarks: address.memo,
                    items_total: itemsTotalPerVendor,
                    status: 'pending_merchant_response',
                    payment_method: 'online_payment',
                    payment_status: 'pending',
                }))
                setIsLoading(false)

            }
            )


            // alert(data.paymentMethod)

        } else {
            // let description=cartItems[0].title
            //console.log(`we should reach here for al nashmi ${data.paymentMethod}`)
            axios.post('https://feehla-checkout-api.herokuapp.com/checkout/pay-order', { grand_price: itemsTotalPrice + shippingCharge, email: address.email, username: address.person_name, description: description }).then(async function (response) {
                //////console.log(`catched create pay response from frontend ${JSON.stringify(response)}`);
                if (response.data['error-code'] == 0) {
                    /* because the key is string so this is intead of [0] */
                    //////console.log('should navigate');
                    /* navigate('/sadad-create')*/
                    localStorage.setItem('transId', response.data["transaction-reference"])
                    localStorage.setItem('userInfo', JSON.stringify(user.userInfo))
                    localStorage.setItem('cartItems', JSON.stringify(cartItems))
                    localStorage.setItem('shippingMethod', JSON.stringify(addToCart.shippingMethod))

                    window.location.href = response.data['payment-url'];
                    //  setIsLoading(false)

                    /* this is to redirect to external url */
                } else {
                    //////console.log(response.data);
                }
            }).catch(function (error) {
                //////console.log(error);
                setIsLoading(false)

            });
            /* if payment successful we will create all orders with is paid check the success url for it and in applet the success url takes you to this page, and save the shipping in local storage so on payment refresh it stays */

            /*             let perVendor = uniqueVendors.map((vendor) => {
                            return cartItems.filter((item) => item.vendor == vendor)
            
                        })
                        perVendor.map((order) => {
                            /* order [{"id":172844,"title":"Turkish coffee set tropic 6 pcs","price":49.5,"stock":2,"image":"[aws_images_bucket_bh]/images/1589621356-826.jpg","vendor":"Oda Decor ","qty":1}] */
            /*
                            
                            const itemsTotalPerVendor =order.reduce((acc, item) => acc + item.price * item.qty, 0)
            
                            const orderPerVendor={
                                vendor_id:order[0].vendor_id,
                                items_json: JSON.stringify(order),
                                time:time,
                                user_id:id,
                                address_json:JSON.stringify(address),
                                grand_price:shippingPerOrder+ itemsTotalPerVendor,
                                payment_method:data.paymentMethod,
                                shipping_cost: shippingPerOrder,
                                user_email:email,
                                delivery_option:shippingMethod,
                                remarks:address.memo,
                                items_total:itemsTotalPerVendor,
            
                            }
            
                         dispatch(createOrderAction({
                                vendor_id:order[0].vendor_id,
                                items_json: JSON.stringify(order[0]),
                                time:time,
                                user_id:id,
                                address_json:JSON.stringify(address),
                                grand_price:shippingPerOrder+ itemsTotalPerVendor,
                                payment_method:data.paymentMethod,
                                shipping_cost: shippingPerOrder,
                                user_email:email,
                                delivery_option:shippingMethod,
                                remarks:address.memo,
                                items_total:itemsTotalPerVendor,
                            }))
                        
                        }
                        )
             */




        }


        dispatch(deductQtysAction(cartItems))

    }
    return (
        <>
            <OrderSummary></OrderSummary>

            {isLoading ? <LoadingSpinner /> : (
                <div className='content'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onPayment}
                    >{({ values }) => (


                        <Form className='wrap'>
                            <div className="main">
                                <MainHeader></MainHeader>



                                <div className='method'>
                                    <div className='methodP'>
                                        <h2>Payment</h2>
                                        <div id="my-radio-group">{/* {JSON.stringify(cartItems)} */}</div>
                                        <div role="group" aria-labelledby="my-radio-group" className='contentField'>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="paymentMethod" value="cash_on_delivery"
                                                            disabled={shippingCharge === 4.4}

/*                                                     checked={shippingCharge!==4.4}
 disabled={shippingCharge===4.4}
 */                                                    />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioDays'>Cash/Benefit on delivery {shippingCharge === 4.4 ? <small>(Not Available for International)</small> : ''}
                                                        </span><span className='radioAccessory'></span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="paymentMethod" value="online_payment" />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioSadad'>

                                                            <img src={sadad}></img>
                                                        </span>
                                                        <span className='radioP'>Online Payment</span>
                                                    </div>
                                                </label>
                                            </div>
                                            {/*                                             <div>Picked: {JSON.stringify(values)}</div>
 */}                  </div>
                                    </div>
                                    <div className='stepFooter'>

                                        <button className='stepButton' type="submit" disabled={isLoading}><span>Confirm Order</span></button>
                                    </div>
                                </div>
                            </div>
                            <SideBarSummary></SideBarSummary>

                        </Form>
                    )}

                    </Formik>
                </div>
            )}
        </>
    );



}

export default AppPayment;