import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../selectAddress/SelectAddress.css'
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';

import { saveAddressAction, selectAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import { useEffect, useState } from "react";
import MapComponent from '../../components/mapSnippet/MapComponent';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
const SelectAddress = () => {
    /* the listing will be similar to productsList */
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const addToCart = useSelector((state) => state.addToCart)
    const { loading, error, selectAddress } = addToCart
    const addresses = addToCart.selectAddress
    const lastAddress = addToCart.address
    const cartItems = addToCart.cartItems
    const { shippingMethod, shippingCharge } = addToCart.shippingMethod
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    ////console.log('Addresses ', JSON.stringify(addresses));
    //const shippingMethod = addToCart.shippingMethod
    const user = useSelector((state) => state.user)
    const userId = user.userInfo.id;
    ////console.log('from shipping user state id ', userId);
    const initialValues = {
        selected: lastAddress.id,
        address: ''
    };
    const [addressState, setAddressState] = useState(lastAddress.id)
    const [onDelete, setonDelete] = useState(false)
    const onContinueClick = (data) => {
        axios.get('https://feehla-checkout-api.herokuapp.com/checkout/select-one-address', { params: { addressId: addressState } }).then((response) => {
            /* the error probably in address state. */
            ////console.log('address selected ', response)
            dispatch(saveAddressAction(response.data[0]))
            navigate(`/payment`)


        }).catch((err) => {
            ////console.log(err)
        })

    };
    const onEditAddress = (id) => {
        //e.preventDefault();
        axios.get('https://feehla-checkout-api.herokuapp.com/checkout/select-one-address', { params: { addressId: id } }).then((response) => {

            ////console.log('address selected ', response)
            dispatch(saveAddressAction(response.data[0]))
            navigate(`/edit-location`)


        }).catch((err) => {
            ////console.log(err)
        })


    };
    const onDeleteAddress = (id) => {
        //e.preventDefault();
        //alert(id)
        axios.get('https://feehla-checkout-api.herokuapp.com/checkout/delete-one-address', { params: { addressId: id } }).then((response) => {

            ////console.log('address deleted ', response)
            //dispatch(saveAddressAction(response.data[0]))
            // navigate(`/edit-location`)
            //setonDelete(!onDelete)

        }).catch((err) => {
            ////console.log(err)
        })
        alert(userId)
        axios.get('https://feehla-checkout-api.herokuapp.com/checkout/address', { params: { userId: userId } }).then((response) => {

            /* unlike post when u pass data through get, with params. and from express you get them with req.query.userId */
            ////console.log('address default ', response)
            dispatch(saveAddressAction(response.data[0]))
            /*              response.data[0] ? navigate('/select-address') : navigate('/shipping')
             */
            setonDelete(!onDelete)

        }).catch((err) => {
            ////console.log(err)
            navigate('/location')
        })


    };


    useEffect(() => {
        ////console.log('is dispatch fired');

        dispatch(selectAddressAction(userId))
        /*
       use effect is replacement of on mounted. which will run once here after the initial render, because of the below empty array. and it is used only for api requests because the page will initaially render empty array before we got a response from the backend. if u   */    //}).catch()
    }, [dispatch, onDelete])

    return (
        <>
            <Navigation>        </Navigation>
            <OrderSummary></OrderSummary>

            <div className='content'>
                {loading ? <h3>loading...</h3> : error ? <h2>{error}</h2> : (

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={onContinueClick}
                    >
                        {({ values, handleChange }) => (
                            /* we get those from formik props */
                            <Form className='wrap'>
                                <div className="main">
                                    <MainHeader></MainHeader>

                                    <CheckoutSteps step1 step2></CheckoutSteps>

                                    {/* call thr name of the field to get its value */}
                                    <div className='method'>
                                        <h2>Select a shipping address</h2>
                                        <div id="my-radio-group">{/* {JSON.stringify(cartItems)} */}</div>
                                        <div role="group" aria-labelledby="my-radio-group" className=''>

                                            {selectAddress ? (selectAddress[0].map((address, index) => (
                                                <label className='addressS'>
                                                    <div key={index} >
                                                        <Field className='radioButtonS radioButton' type="radio" name="address" value={address.id} checked={address.id == addressState ? true : false} onChange={(e) => {
                                                            /* we grt onchange from formik props */
                                                            ////console.log(`addresstate ${addressState} target ${e.target.value}`)
                                                            ;
                                                            setAddressState(e.target.value)
                                                            /* we will set the address state to the radio button e selected value which is the current address id so they can equual and we get rerender  */

                                                        }} />

                                                        <div className="mapContainer" >
                                                            <h1 className="addressTitle">
                                                                {address.title}
                                                            </h1>
                                                            <MapComponent lat={address.location_latitude} lng={address.location_longitude}></MapComponent>
                                                            <div className='radioWrapper'>
                                                                <div className='radioLabelS'>
                                                                    <p className='radioDays'> {address.building} {address.road} {address.area} {address.block} </p>
                                                                </div>
                                                            </div>
                                                            <div className="editAddress">
                                                                <button type='button' className="underline-effect reset-button" onClick={() => onEditAddress(address.id)}>
                                                                    {/* u call on click funcion like this to pass the id as a call back */}
                                                                    Edit Address
                                                                </button>
                                                                <button type='button' className="underline-effect reset-button" onClick={() => onDeleteAddress(address.id)}>
                                                                    Delete Address
                                                                </button>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </label>
                                            ))) : 'fuck'}
                                            <Link to={'/location'} className="mapContainer addAddress" >
                                                <span class="material-symbols-outlined">
                                                    add_circle
                                                </span>                                        </Link>

                                        </div>

                                        {/*                             <div>picked:{values.address}</div>
 */}
                                        <div className='stepFooter'>

                                            <button className='stepButton' type="submit"><span>Continue</span></button>
                                        </div>
                                    </div>
                                </div>
                                <SideBarSummary></SideBarSummary>

                            </Form>
                        )}

                    </Formik>
                )}
            </div>
        </>
    );



}

export default SelectAddress;

