import React from 'react';
import './signUp.css';
import { Link } from 'react-router-dom'
import { signInWithGooglePopup, checkUser } from '../../utils/firebase';
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from 'axios'
const SignUp = () => {
    const result = async () => {
        const response = await signInWithGooglePopup()
        /* the response is authenticated between firebase and google  */
        ////console.log(response);
        /* {
            u will get these keys under the user object
    "uid": "VdMkCmZrIrdq6g0lEDFX0LrYhk62",
    "email": "ne3emi@gmail.com",
    "emailVerified": true,
    "displayName": "Mohammed Alnoaimi",
    "isAnonymous": false,
    "photoURL": "https://lh3.googleusercontent.com/a-/AOh14GjcEpnV-vuCcdpqkUNKJ1KQeKFjm8XtqizK6Q321A=s96-c",
    "providerData": [
        {
            "providerId": "google.com",
            "uid": "100404477398677211126",
            "displayName": "Mohammed Alnoaimi",
            "email": "ne3emi@gmail.com",
            "phoneNumber": null,
            "photoURL": "https://lh3.googleusercontent.com/a-/AOh14GjcEpnV-vuCcdpqkUNKJ1KQeKFjm8XtqizK6Q321A=s96-c"
        }
    ],
    "stsTokenManager": {
        "refreshToken": "AIwUaOmhXVYEHJH0l0n35VcUVekZHeiP1uLIg_2Xs26VAW5-vzqFh_RBHg324_BrQdtPbpoIUsMDw_txiStKmh6UHbXvluquzBYI0LbcgHhOd0S7MeyEcP33qwPfEilGuKxghzzF2c5R7BshwqlQ3zDDDQCete6BmSONt-sb0FoQ51Mz9dF-Fr4Rnpcj_V04fo6g_XP7NAC5gCjfwRj3qcNAuEfCkjQGMHYLWDttOsKpZho14xSD5ht6QE2iHQyFlI7hfHnS_8Be1_InTJPfqng_Qzb_ldlGQCp5N9JD9fMxnCBmXOgiC9XVmjTmVUtP2fEoWjVO9WUyE65tAKYKAAFed4y4WySoXrzBqVOYbS8-g3PmUo7k2g-t-Y0j-MChVFV9AZdTm2Hah8Jng3B4PPBxXhh9CJ_CG_4LK_W0IOfbbL0PsIHaJ0c",
        "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImYxZDU2YTI1MWU0ZGRhM2Y0NWM0MWZkNWQ0ZGEwMWQyYjlkNzJlMGQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiTW9oYW1tZWQgQWxub2FpbWkiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2pjRXBuVi12dUNjZHBxa1VOS0oxS1FlS0ZqbThYdHFpeks2UTMyMUE9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZmlyLWF1dGgtNDIyNWQiLCJhdWQiOiJmaXItYXV0aC00MjI1ZCIsImF1dGhfdGltZSI6MTY0NzU2MTgwMCwidXNlcl9pZCI6IlZkTWtDbVpySXJkcTZnMGxFREZYMExyWWhrNjIiLCJzdWIiOiJWZE1rQ21acklyZHE2ZzBsRURGWDBMclloazYyIiwiaWF0IjoxNjQ3NTYxODAwLCJleHAiOjE2NDc1NjU0MDAsImVtYWlsIjoibmUzZW1pQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAwNDA0NDc3Mzk4Njc3MjExMTI2Il0sImVtYWlsIjpbIm5lM2VtaUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.ZjG3SZjtDAsF8lZzssrkW6HZeUX7TbKkkhs3cgBCqxD64tqrIjEMR1KyDW5blfOBR55gn62_sK5Xl_ZlXhXDbZ3p9jFKyZSbipOypYGxJgdVdaDyeLeNg5sVBapPpkNwXnEdkA_lkmqQ8sUTVBzDsEqBF6GkGx2Cz2FAWqzmaKD6edChYkHwLlM1ipYQxi2Bh4Ou3zZ8XgubJOY81iUYNp1WgSEzceRY0XpFbENpy5rmvgnsoN6DxMj8sYShn3eagK3foMsUwda2tt-oFW9Bf6RfGvcBLbApCZs2Hc_mCtss5cVpYZ9UcPXHLg5oZGcMhL80NbO1FNThLDDzMX7EmA",
        "expirationTime": 1647565400755
    },
    "createdAt": "1645922001464",
    "lastLoginAt": "1647561800443",
    "apiKey": "AIzaSyAAIrHFQYmQ_dnuz04-hrAOmItfrAYi5-Q",
    "appName": "[DEFAULT]"
} */
        const check = await checkUser(response.user)
    }
    const onSubmit = (data) => {
        axios.post("https://feehla-checkout-api.herokuapp.com/user/sign-up", data).then((response) => {
            ////console.log('from signupjsx ', response);
        });
    };

    const initialValues = {
        displayName: "",
        email: "",
        password: "",
        rePassword: ""
    };

    return (
        <><Formik initialValues={initialValues}
            onSubmit={onSubmit}
        >


            <div className="form-container">
                <Form id="signin-form">



                    <ul className="form-items">
                        <li>
                            <h1>Sign-Up</h1>
                        </li>
                        <li>
                            <label >Name</label>
                            <Field type="name" name="displayName" id="displayName" />
                        </li>

                        <li>
                            <label >Username</label>
                            <Field type="name" name="email" id="email" />
                        </li>
                        <li>
                            <label >Password</label>
                            <Field type="password" name="password" id="password" />
                        </li>
                        <li>
                            <label >Re-Enter Password</label>
                            <Field type="password" name="rePassword" id="rePassword" />
                        </li>

                        <li>
                            <button type='submit' className="primary">Sign up</button>
                        </li>
                        <li>
                            <div className='or'>

                                <div className='divider'></div>
                                <h5>or</h5>
                            </div>
                        </li>
                    </ul>
                </Form>
            </div>

        </Formik>
            <div className="form-container">

                <ul className="form-items">

                    <li>
                        <div className="googleContainer">
                            <form id="signin-form">
                                <button id='google-button' className="login-with-google-btn" type='button' onClick={result}>
                                    {/* run result func, type button because the default is submit a form*/}
                                    Sign up with Google
                                </button>

                            </form>
                        </div>

                    </li>
                    <li className='new-user'>
                        <div>
                            Already have an account?
                            <Link to='/sign-in'>
                                Sign In
                            </Link>
                        </div>

                    </li>
                </ul>
            </div>
        </>


    )
}

export default SignUp;