import React from 'react';
import { useEffect } from 'react';
import axios from "axios";
import { Link, useParams, useNavigate } from 'react-router-dom'
import '../product/ProductPage.css';
import { useDispatch, useSelector } from "react-redux";
import { productDetailsAction } from "../../actions/productDetailsAction";
import SiteNavigation from '../siteNavigation/SiteNavigation';
const ProductPage = () => {
    let productId = useParams().id
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const productDetails = useSelector((state) => state.productDetails)
    /* select the product state from the store */
    const { loading, error, product } = productDetails
    ////console.log(`from productPage ${JSON.stringify(product)}`)
    /* just put up import useParam and store it in a variable. because the url is fake, so we wont get the id from request, so this library will get it*/
    // ////console.log(`from productpage ${JSON.stringify(productId) }`)
    /*     const [product, setProduct] = useState({});
     */    /* define product as an empty object in useState. and whenever setProduct is called which will set the product below from data.response, the useState will react to the state changed and will rerender the below return with the filled product object, unlike my previous attempt which passed an empty array */
    /* let products=[];*/
    useEffect(() => {
        dispatch(productDetailsAction(productId,1))
    }, [dispatch])
    /* the array here tells us when to change this, normally only at the beginning unless we have seconf variable which we want it to be dependent on it */
const addToCart = () => { 
    navigate(`/cart/${productId}`)
    /* so the button will fire this function that will take us to above url to grab the productid and go fetch it from server and store it in the cart local storage */
 }
    return (
        <>
        <SiteNavigation></SiteNavigation>

        <div className="contentp">

            {/*        <!------ rating header  ------>
 */}
            <div className="details-header">
                <div className="back-to-result">
                    <Link to={"/"}>
                        Back to results
                    </Link>
                </div>
            </div>
            {/*             <!------ title ------>
 */}          <div className="details">
                <p>{product.title}</p>
                <div className="flag">

                    {/*                  <!------image ------>
 */}             
  <div className="details-image">
      
         {product.image?product.image.split('/')[0]!=='[aws_images_bucket]'? (<img src={'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/'+product.image.split('/images/')[1]} alt="" />)
:(<img src={'https://s3.us-east-2.amazonaws.com/feehla-images-uploads/images-300x300/'+product.image.split('/images/')[1]} alt="" />)

:""}


                        {/*                 <img src="" alt="<%=product.title%>" />
 */}              </div>
                </div>
                <div className="content-divider"></div>
                <div className="details-info">
                    {/*                     <!------ price ------>
 */}
                    <ul>
                        <li>
                            <div className="price">
                                <h2 className="currency">BHD</h2>
                                <h2 className="bd">{product.price}</h2>
                                {/*                                 <h2 className="fils">{typeof product.price.toString().split('.')[1] == 'undefined' ? '00' : product.price.toString().split('.')[1].slice(0, 1) + '0'}</h2>
 */}                            </div>

                        </li>
                        <li>
                                    {product.stock > 0?<>
                      <span class="success">In Stock</span>
                      <button id="add-button" className="fw primary" onClick={addToCart}>Add to Cart
                      </button></>
:
                      <span class="error">Unavailable</span>
                  }
                </li>

                        <li>
{/*                             <form action="/cart/<%=product.id%>" method='POST'>
                                <button id="add-button" className="fw primary">Add to Cart
                                </button>
                            </form>
 */}                        </li>
                    </ul>
                </div>


            </div>
        </div>
        </>

    );
}


export default ProductPage;