import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import './delivery.css';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { saveAddressAction } from '../../actions/addToCartAction';
import { useNavigate } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';

const ZadDelivery = (props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const onSubmit = (value) => {
     console.log(`values submitted ${JSON.stringify(value)}`)

        //update item price named package and make order
     axios.post('https://feehla-checkout-api.herokuapp.com/checkout/vendor-delivery', { order:value }).then((response) => {
        alert(`Your order ${response.data.id} is created`)
        window.location.reload(false);

        // navigate('/bt-delivery')

//console.log('did we reach here');
console.log(`added address to be saved${JSON.stringify(response)}`)
                           //dispatch(saveAddressAction(response.data[0]))
/*                             navigate(`/payment`)
 */                       
 //navigate('/payment')

                

                
            ////console.log('worked')
        }).catch((err) => { console.log(err) })

    }

    let initialValues = {
        title: '',
        name: '',
        email: '',
        building: '',
        road: '',
        block: '',
        area: '',
        location: '',
        phone_number: '',
        lat: '',
        lng: '',
        memo: '',
        shippingMethod:'standard',
        amount:0,
        vendor_id:531,
        item_id:435948,
        vendor_name:'Zad Delivery'
    }
    const validate = (values) => {
        const errors = {};
        if (!values.building || values.building == '') {
            errors.building = 'Required';

        }
        if (!values.road) {
            errors.road = 'Required';

        }
        if (!values.area) {
            errors.area = 'Required';
        }
        if (!/[+-\d\s]{4,}$/.test(values.phone_number)) {
            errors.phone_number = 'invalid';
        }

        //...
        ////console.log('errors: ', JSON.stringify(errors));
        ////console.log('values: ', JSON.stringify(values));

        return errors;
    };
    return (
        <>
            <Navigation>        </Navigation>

            <div className="content">
                <Formik validate={validate} onSubmit={onSubmit} initialValues={initialValues}>
                    {({ values }) => {
                        /* props.values is same as use state and renders on change every letter is entered is setState, and we grab the state from props.values, */
                        {/* if form values are there use them otherwise use initial values */ }
                        return (
                            <Form className='wrap' id="shipping-form" method='post' action='/payment'>
                                <div className="main">
                                    <MainHeader></MainHeader>



                                    <ul className="form-itemsB">
                                        <li>
                                            <h2>Feehla Shipment</h2>
                                        </li>
                                        <li>
                                            <label >Address Title</label>
                                            <Field type="text" name="title" id="title" placeholder='Work/Home...(optional)' className='clean' />                             </li>                                       <li>                                            <label >Name </label>
                                            <Field className='clean' type="text" name="name" id="name"
                                            placeholder='You can put customer name or your order Id (optional)' />

                                        </li>
                                        <li>
                                            <label >Email </label>
                                            <Field className='clean' type="text" name="email" id="email" placeholder='(optional)'/>

                                        </li>
                                        
                                        <li>

                                            <label >Building </label>
                                            {!values.building ? <Field className='dirty' type="text" name="building" id="building" /> : <Field className='clean' type="text" name="building" id="building" />}
                                            <ErrorMessage name='building' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Road </label>
                                            {!values.road ? <Field className='dirty' type="text" name="road" id="road" /> : <Field className='clean' type="text" name="road" id="road" />}
                                            <ErrorMessage name='road' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Block </label>

                                            {!values.block ?
                                                <Field className='dirty' type="text" name="block" id="block" />
                                                : <Field className='clean' type="text" name="block" id="block" />}
                                            <ErrorMessage name='block' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >phone_number</label>
                                            {!values.phone_number ? <Field className='dirty' type="text" name="phone_number" id="phone_number" /> : <Field className='clean' type="text" name="phone_number" id="phone_number" />}
                                            <ErrorMessage name='phone_number' component='p'></ErrorMessage>

                                        </li>
                                        <li>
                                            <label >Additional Directions</label>
                                            <Field type="text" name="memo" id="memo" placeholder='(optional)' className='clean' />
                                        </li>

                                        <li>
                                            <label >Area </label>
                                            {!values.area ? <Field className='dirty' type="text" name="area" id="area" /> : <Field className='clean' type="text" name="area" id="area" />}
                                            <ErrorMessage name='area' component='p'></ErrorMessage>

                                        </li>

                                        <li>
                                            <label >Location url</label>
                                             <Field className='clean' type="text" name="location" id="location" />
                                        </li>
                                        <li>
                                   <label >Amount to be collected - Leave 0 if paid</label>
                                   {<Field className='clean' type="text" name="amount" id="amount" placeholder='without delivery. If paid put 0'/>}
                                        </li>
                                        <li>
                                        <div className='methodP'>
                                        <h2>Method</h2>
                                        <div id="my-radio-group">{/* {JSON.stringify(cartItems)} */}</div>
                                        <div role="group" aria-labelledby="my-radio-group" className='contentField'>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="shippingMethod" value="standard"
                                                

                                                    />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioDays'>Normal 
                                                        </span><span className='radioAccessory'></span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="shippingMethod" value="express" />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioP'>Express</span>
                                                    </div>
                                                </label>
                                            </div>
{/*                                              <div>Picked: {JSON.stringify(values)}</div>
 */}                  </div>
                                    </div>
</li>

                                        <li>
                                            <button type='submit' className="primary">Continue</button>
                                        </li>
                                    </ul>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            </div>

        </>

    )
}



export default ZadDelivery;