
import { initializeApp } from 'firebase/app';
import axios from 'axios';
/* the instance of the app to configure it with your credentials */
import {getAuth,signInWithRedirect,signInWithPopup,GoogleAuthProvider,OAuthProvider} from 'firebase/auth';
/* the instances of auth methods */
import { Link , useNavigate } from 'react-router-dom'
import * as firebase from "firebase/app";
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBnf0FISfy9OxvfLef5AIryDVE5hMhWf6g",
  authDomain: "auth.feehla.com",
  databaseURL: "https://enaseej-383fe.firebaseio.com",
  projectId: "enaseej-383fe",
  storageBucket: "enaseej-383fe.appspot.com",
  messagingSenderId: "229248064552",
  appId: "1:229248064552:web:e96e9079874a83de5da91d",
  measurementId: "G-VB62BF5KFH"};

  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  /* put the configs in  the app instance to connect it */

  const auth = getAuth();
const provider = new OAuthProvider('apple.com');
export const signInWithApplePopup = () => signInWithPopup(auth, provider);

/* export const signInWithApplePopup = async() => {
  const result = await auth.signInWithPopup(provider);
  //console.log(result.user); // logged-in Apple user}
}
 */
