import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
} from "@react-google-maps/api";
import mapStyles from "../mapStyles";
/* https://github.com/leighhalliday/google-maps-react-2020/blob/master/src/App.js
https://github.com/jaewonhimnae/react-google-map/blob/master/src/views/LandingPage.js
*/
const MapComponent = ({lat,lng}) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyDk7GtS43u9ujKnI4XrG-uUGWoFUCWToOs",
    
        /* first we load the script with the apikey and enabled libraries, we put it outside to avoid rerenders, and it will give us back isloaded or error */
    })

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    const mapContainerStyle = {
/*         width: '86vw',
 */        height: '20vh',
    }
    const center = {
        lat: lat,
        lng: lng
}
const options = {
    disableDefaultUI: true,
    styles:mapStyles,
}
return(
    <>
    <div className='googleMap'>

    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={17} center={center} options={options}
        

    /* we will store the event in useRef hook so we dont rerender everytime we move the map */
    >
        <Marker
            position={{
                lat: lat,
                lng: lng
            }}
            icon={{
                url: `/user.svg`,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 20),
                scaledSize: new window.google.maps.Size(40, 40),
              }}

        /* we get this from the state */
        />

    </GoogleMap>
</div>


    </>
    )
  
    }
  export default MapComponent
