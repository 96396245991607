/* third step of redux */
import { createStore, combineReducers, applyMiddleware } from "redux";
/* the store is just to organize the reducers (products-users etc...) */

import thunk from "redux-thunk";
/* thunk is to handle asynchronous functions for api requests and then send it to reducer. it is the async for redux */
import { composeWithDevTools } from "redux-devtools-extension";
/* for debugging */
import { productsListReducer } from "./reducers/productReducers";
import { productsDetailsReducer } from "./reducers/productDetailsReducer";
import { addToCartReducer } from "./reducers/addToCartReducer";
import { userReducer } from "./reducers/userReducer";
import { createOrderReducer } from "./reducers/orderReducer";

const reducer = combineReducers({
  productList: productsListReducer,
  productDetails: productsDetailsReducer,
  user: userReducer,
  addToCart: addToCartReducer,
  createOrder: createOrderReducer,
  /* this is basically what we will get from server and will pass it to any fucking function */
  /* u will see it in the dev tool under state */
});
const cartFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];
/* we will get the stored cart items to put it in the initial state of the store so when we close the window and come back, add to cart state will initialize itself from the local storage*/
const userFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
//const addressFromStorage = typeof localStorage.getItem("address")==='undefined'?{}:localStorage.getItem("address")===null?{}:localStorage.getItem("address")===''?{}:localStorage.getItem("address")? JSON.parse(localStorage.getItem("address")) : {};
  const orderFromStorage = localStorage.getItem("order")
  ? JSON.parse(localStorage.getItem("order"))
  : [];
  const shippingFromStorage = localStorage.getItem("shippingMethod")
  ? JSON.parse(localStorage.getItem("shippingMethod"))
  : {};


const initialeState = {
//addToCart: { cartItems: cartFromStorage, address: addressFromStorage, shippingMethod: shippingFromStorage },
  addToCart: { cartItems: cartFromStorage, shippingMethod: shippingFromStorage },

  createOrder: { order: orderFromStorage },

  user: { userInfo: userFromStorage },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialeState,
  composeWithDevTools(applyMiddleware(...middleware))
);
/* initiate the instance of the store */
export default store;
