import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap'
import '../sideBarSummary/SideBarSummary.css'
const SideBarSummary = ({ shippingPrice, outOfStock, discountAmount, discountCode }) => {
    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    const shippingCondition = shippingPrice === 0 ? true : shippingPrice > 0 ? true : false

    //const { shippingMethod, shippingCharge } = addToCart.shippingMethod
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    const [expand, setExpand] = useState(false)
    ////console.log(`from summary ${shippingPrice}`)
    const shippingRedux2 = addToCart.shippingMethod.shippingCharge

    useEffect(() => {


    }, [shippingRedux2])

    return (
        <aside className='sidebar'>
            <div className='order-summary__sections'>
                {cartItems.length === 0 ? (
                    <>
                        <div>Cart is empty. </div>
                        <Link to="/">Go Shopping</Link>
                    </>)

                    :
                    (cartItems.map((item) => {
                        /*                 <!------ item begins ------>
                         */
                        return (
                            <>
                                <div className="order-summary__section order-summary__section--product-list">
                                    <table className="product-table">
                                        <tbody data-order-summary-section="line-items">
                                            <tr className="product">
                                                <td className="product__image">
                                                    <div class="product-picS">
                                                        {item.image ? item.image.split('/')[0] !== '[aws_images_bucket]' ? (<img src={'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/' + item.image.split('/images/')[1]} alt="" />)
                                                            : (<img src={'https://s3.us-east-2.amazonaws.com/feehla-images-uploads/images-300x300/' + item.image.split('/images/')[1]} alt="" />) : ''}

                                                        <span className='cartCircleS'>

                                                            <p>
                                                                {item.quantity}
                                                            </p>
                                                        </span>

                                                    </div>
                                                </td>

                                                <th className="product__description" scope="row">

                                                    <a href={`https://feehla.com/product/${item.id}/i`} className='product__description__name order-summary__emphasis'>
                                                        {item.name_en}
                                                    </a>

                                                </th>
                                                <td class="product__price">

                                                    <span className="order-summary__emphasis skeleton-while-loading">BHD</span>
                                                    <span className="order-summary__emphasis skeleton-while-loading">{item.price}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </>
                        )
                    }))}


                <div class="order-summary__section order-summary__section--total-lines" data-order-summary-section="payment-lines">
                    <table class="total-line-table">
                        <tbody class="total-line-table__tbody">
                            <tr class="total-line total-line--subtotal">
                                <th class="total-line__name" scope="row">Subtotal</th>
                                {discountAmount && <td></td>}

                                <td class="total-line__price">
                                    <span class="order-summary__emphasis skeleton-while-loading" data-checkout-subtotal-price-target="3200">
                                        BD {itemsTotalPrice.toFixed(3)}
                                    </span>
                                </td>
                            </tr>

                            {discountAmount && (
                                <tr class="total-line total-line--subtotal">
                                    <th class="total-line__name" scope="row">
                                        <span>
                                            Discount
                                        </span>
                                    </th>
                                    <td className='tag'>
                                        <div className='tagContainer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 473" width='20px' height='20px' fill='grey'><path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>
                                            <span >
                                                {'   ' + discountCode}
                                            </span>
                                        </div>

                                    </td>
                                    <td class="total-line__price">
                                        <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                            - BD {discountAmount.toFixed(3)}

                                        </span>
                                    </td>
                                </tr>

                            )}


                            <tr class="total-line total-line--shipping">
                                <th class="total-line__name" scope="row">
                                    <span>
                                        Shipping
                                    </span>

                                </th>
                                {discountAmount && <td></td>}

                                <td class="total-line__price">
                                    <span class="skeleton-while-loading order-summary__emphasis" data-checkout-total-shipping-target="3999">
                                        BD {shippingCondition ? shippingPrice : shippingRedux2}
                                    </span>
                                </td>
                            </tr>



                            <tr class="total-line total-line--taxes hidden" data-checkout-taxes="">
                                <th class="total-line__name" scope="row">
                                    Taxes
                                </th>
                                <td class="total-line__price">
                                    <span class="order-summary__emphasis skeleton-while-loading" data-checkout-total-taxes-target="0">$0.00</span>
                                </td>
                            </tr>




                        </tbody>
                        <tfoot class="total-line-table__footer">
                            <tr class="total-line">
                                <th class="total-line__name payment-due-label" scope="row">
                                    <span class="payment-due-label__total">Total</span>
                                </th>
                                {discountAmount && <td></td>}

                                <td class="total-line__price payment-due" data-presentment-currency="USD">
                                    <span class="payment-due__currency remove-while-loading">BD</span>
                                    <span class="payment-due__price skeleton-while-loading--lg" data-checkout-payment-due-target="7199">
                                        {(itemsTotalPrice + (shippingCondition ? shippingPrice : shippingRedux2) - (discountAmount ? discountAmount : 0)).toFixed(3)}
                                    </span>
                                </td>
                            </tr>

                        </tfoot>
                    </table>




                </div>


            </div>
        </aside>
    )
}

export default SideBarSummary