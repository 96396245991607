import { Link, useNavigate, useParams } from 'react-router-dom'
import 'remixicon/fonts/remixicon.css'
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    CardHeader,
    Collapse
} from "reactstrap";
import axios from 'axios'

import { useEffect, useState } from "react";
import classnames from "classnames";
import '../orderStatus/orderStatus.css'

export const OrderStatus = ({ order }) => {
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(true);
    const [col3, setcol3] = useState(true);
    const [toggleAssigned, setToggleAssigned] = useState(false);
    const [toggleDelivered, setToggleDelivered] = useState(false);

    function togglecol1() {
        setcol1(!col1);
    }

    function togglecol2() {
        setcol2(!col2);
    }

    function togglecol3() {
        setcol3(!col3);
    }

    let assigned = false;
    let delivered = false;

    let afterAssigned = []
    let afterPlaced = []
    let afterDelivered = []
    if (order.length) {
        const filteredRemarks = JSON.parse(order[0].remarks_v2) ? JSON.parse(order[0].remarks_v2).map((remark, index) => {
            if (remark.remark === 'driver_assigned') {
                assigned = true
                //setToggleAssigned(true)
            } else if (remark.remark === 'delivered') {
                //setToggleDelivered(true)
                delivered = true;
                assigned = false;
            }


            if (assigned) {
                afterAssigned.push(remark)
            } else if (delivered) {
                afterDelivered.push(remark)
            }
            else {
                afterPlaced.push(remark)
            }
        }) : []
    }
    const getDate = (iso) => {
        var dt = new Date(iso * 1000);
        dt.setDate(dt.getDate() + 3)
        var expected = new Date(iso * 1000)
        expected.setDate(expected.getDate() + 1)

            .toString().slice(0, 10);
        const deliveryTime = `Expected delivery between \n ${expected.toString().slice(0, 10)} and ${dt.toString().slice(0, 10)}`
        return deliveryTime;
    }
    const getFullDate = (iso) => {
        var dt = new Date(iso * 1000);
        const orderDate = `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()} - ${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        return orderDate;
    }
    const getFullDateMonth = (iso) => {
        var dt = new Date(iso * 1000);
        const orderDate = `${dt.toLocaleString('en-US', { month: 'short' })} ${dt.getDate()} - ${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        return orderDate;
    }
    const getFullDateDay = (iso) => {
        var dt = new Date(iso * 1000);
        const orderDate = `${dt.toLocaleString('en-US', { weekday: 'short' })}, ${dt.getDate()} ${dt.toLocaleString('en-US', { month: 'short' })} ${dt.getFullYear()} - ${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`
        return orderDate;
    }
    const onCancelOrder = () => {
                
           //update item price named package and make order
    axios.post('https://feehla-checkout-api.herokuapp.com/orders/cancel', { orderId:order[0].id }).then((response) => {
           alert(`Your ordered is cacelled`)
           window.location.reload(false);
          //history.push("/driver-dashboard");

           }).catch((err) => { console.log(err) })
    
       }


    return (

        <Card>
            <CardHeader>
                <div className="d-sm-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">Order Status</h5>
                    <div className="flex-shrink-0 mt-2 mt-sm-0">
                        {order[0].driver_name === '' || order[0].driver_name === null? (
                            <button
                                to="#"
                                className="btn btn-soft-danger btn-sm mt-2 mt-sm-0" onClick={() => {
                                    onCancelOrder();
                                }}

                            >
                                <i className="mdi mdi-archive-remove-outline align-middle me-1"></i>{" "}
                                Cancel Order
                            </button>

                        ) : ''

                        }
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                <div className="profile-timeline">
                    <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                    >
                        <div className="accordion-item border-0" onClick={togglecol1}>
                            <div className="accordion-header" id="headingOne">
                                <Link to="#" className={classnames(
                                    "accordion-button",
                                    "p-2",
                                    "shadow-none",
                                    { collapsed: !col1 }

                                )}>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-success rounded-circle">
                                                <i className="ri-shopping-bag-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="fs-15 mb-0 fw-semibold">
                                                Order Placed -{" "}
                                                <span className="fw-normal">
                                                    {getFullDateMonth(order[0].time)}
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Collapse
                                id="collapseOne"
                                className="accordion-collapse"
                                isOpen={col1}
                            >
                                <div className="accordion-body ms-2 ps-5 pt-0">
                                    {afterPlaced ? afterPlaced.map((remark) => {

                                        return (
                                            <>
                                                <h6 className="mb-1">{remark.username}: {remark.remark}</h6>
                                                <p className="text-muted">
                                                    {getFullDateDay(remark.time)}
                                                </p>

                                            </>)
                                    }) : ''}


                                </div>
                            </Collapse>
                        </div>
                        {/* if status === 'driver assigned'?set active, then if remarks time>driver assigned time show remarks filter 
      map through, with two arrays, if driver assigned push in second, else push in first
      */}
                        {(assigned || delivered) ? (
                            <div className="accordion-item border-0" onClick={togglecol2}>
                                <div className="accordion-header" id="headingTwo">
                                    <Link to="#"
                                        className={classnames(
                                            "accordion-button",
                                            "p-2",
                                            "shadow-none",
                                            { collapsed: !col2 }
                                        )}
                                        href="#collapseTwo"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar-xs">
                                                <div className="avatar-title bg-success rounded-circle">
                                                    <i className="ri-takeaway-fill"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="fs-15 mb-1 fw-semibold">
                                                    Driver Assigned -{" "}
                                                    <span className="fw-normal">
                                                        {getFullDateMonth(afterAssigned[0].time)}
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <Collapse
                                    id="collapseTwo"
                                    className="accordion-collapse"
                                    isOpen={col2}
                                >
                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                        {afterAssigned ? afterAssigned.map((remark) => {

                                            return (
                                                <>
                                                    <h6 className="mb-1">{remark.username}: {remark.remark}</h6>
                                                    <p className="text-muted">
                                                        {getFullDateDay(remark.time)}
                                                    </p>

                                                </>)
                                        }) : ''}

                                    </div>
                                </Collapse>
                            </div>
                        ) : (<div className="accordion-item border-0">
                            <div className="accordion-header" id="headingFive">
                                <Link
                                    className="accordion-button p-2 shadow-none"
                                    to="#"
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                <i className="ri-takeaway-fill"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="fs-14 mb-0 fw-semibold">
                                                Driver Assigned
                                            </h6>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        )}
                        {delivered ? (
                            <div className="accordion-item border-0" onClick={togglecol2}>
                                <div className="accordion-header" id="headingTwo">
                                    <Link to="#"
                                        className={classnames(
                                            "accordion-button",
                                            "p-2",
                                            "shadow-none",
                                            { collapsed: !col2 }
                                        )}
                                        href="#collapseTwo"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar-xs">
                                                <div className="avatar-title bg-success rounded-circle">
                                                    <i className="mdi mdi-package-variant"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="fs-15 mb-1 fw-semibold">
                                                    Delivered -{" "}
                                                    <span className="fw-normal">
                                                        {getFullDateMonth(afterDelivered[0].time)}
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="accordion-item border-0">
                                <div className="accordion-header" id="headingFive">
                                    <Link
                                        className="accordion-button p-2 shadow-none"
                                        to="#"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar-xs">
                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                    <i className="mdi mdi-package-variant"></i>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h6 className="fs-14 mb-0 fw-semibold">
                                                    Delivered
                                                </h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </CardBody>
        </Card>

    )

};



