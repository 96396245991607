import React from 'react'
import { Link } from 'react-router-dom'
import './CheckoutSteps.css'
const accessToken=localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).accessToken:''
//const cartId=localStorage.getItem('cartId')?JSON.parse(localStorage.getItem('cartId')):''

//console.log(`access token ${JSON.stringify(accessToken)}`)
function CheckoutSteps({ step1, step2, step3, step4,cartId }) {
    //console.log('cart id from steps ',cartId);
    return (
        <nav aria-label="Breadcrumb">
            <ol className="breadcrumb" role="list">
                {step1?(
                <li className="breadcrumb__item breadcrumb__item--completed">
                <a href='https://feehla.com/' className="breadcrumb__link" >Cart</a>
                <span class="material-symbols-outlined">
                    chevron_right
                </span>
            </li>

                ):(
                    <li className="breadcrumb__item breadcrumb__item--current">
                    <Link to={'https://feehla.com/'}  className="breadcrumb__link">Cart</Link>
                    <span class="material-symbols-outlined">
                        chevron_right
                    </span>
                </li>

                )}
                {step2?(
                <li className="breadcrumb__item breadcrumb__item--completed">
                <Link to={`/shipping/?auth_token=${accessToken}&sl_id=${cartId}`} className="breadcrumb__link" >Shipping</Link>
                <span class="material-symbols-outlined">
                    chevron_right
                </span>
            </li>

                ):(
                    <li className="breadcrumb__item breadcrumb__item--current">
                    <span className="breadcrumb__link">Shipping</span>
                    <span class="material-symbols-outlined">
                        chevron_right
                    </span>
                </li>

                )}
                {step3?(
                <li className="breadcrumb__item breadcrumb__item--completed">
                <Link to={'/select-address'} className="breadcrumb__link" >Address</Link>
                <span class="material-symbols-outlined">
                    chevron_right
                </span>
            </li>

                ):(
                    <li className="breadcrumb__item breadcrumb__item--current">
                    <span className="breadcrumb__link">Address</span>
                    <span class="material-symbols-outlined">
                        chevron_right
                    </span>
                </li>

                )}
                {step4?(
                <li className="breadcrumb__item breadcrumb__item--completed">
                <Link to={'/payment'} className="breadcrumb__link" >Payment</Link>
                <span class="material-symbols-outlined">
                    chevron_right
                </span>
            </li>

                ):(
                    <li className="breadcrumb__item breadcrumb__item--current">
                    <span className="breadcrumb__link">Payment</span>
                </li>

                )}

            </ol>
        </nav>
    )
}

export default CheckoutSteps