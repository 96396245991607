import axios from "axios"

export const createOrderAction = (order) => async (dispatch, getState) => {
  
    dispatch({
      type: 'CREATE_ORDER_REQUEST',
    })


    axios.post(`https://feehla-checkout-api.herokuapp.com/checkout/order`, {order:order}).then(({data}) => { 
        dispatch({
            type: 'CREATE_ORDER_SUCCESS',
            payload: data,
          })
          //console.log('from action order response ', data);
          localStorage.setItem('order',JSON.stringify(getState().createOrder.order))


     }).catch((error) => { 
        dispatch({
            type: 'CREATE_ORDER_FAIL',
            payload:
              error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
          })
      

      })

}

export const createAppOrderAction = (orders) => async (dispatch, getState) => {
  
  dispatch({
    type: 'CREATE_ORDER_REQUEST',
  })


  axios.post(`https://feehla-checkout-api.herokuapp.com/checkout/app-order`, {orders:orders}).then(({data}) => { 
      dispatch({
          type: 'CREATE_APP_ORDER_SUCCESS',
          payload: data,
        })
        console.log('from action order response ', data);
        localStorage.setItem('order',JSON.stringify(getState().createOrder.order))


   }).catch((error) => { 
      dispatch({
          type: 'CREATE_ORDER_FAIL',
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        })
    

    })

}

export const deductQtysAction = (cartItems) => async (dispatch, getState) => {
  


  axios.post(`https://feehla-checkout-api.herokuapp.com/checkout/deduct`, {cartItems:cartItems}).then(() => { 
//console.log('deducted');
   }).catch((error) => { 
    //console.log(error);

    })

}