/* create order from state */
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../paymentSuccess/PaymentSuccess.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createOrderAction } from '../../actions/orderActions';
import { useEffect } from 'react';
import Navigation from '../navigation/navigation';

const PaymentSuccess = () => {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const addToCart = useSelector((state) => state.addToCart)
    // const cartItems = addToCart.cartItems
    const cartItems = JSON.parse(localStorage.getItem('cartItems'))
    const { shippingMethod, shippingCharge } = JSON.parse(localStorage.getItem('shippingMethod'))

    const transId = localStorage.getItem('transId')
    const description = cartItems.map((item) => {
        return item.quantity + ' ' + item.name_en + ' @ BD ' + item.price
    }).join(' | ').toString()
    ////console.log(`cart items description ${JSON.stringify(description)}`)
    // const { shippingMethod, shippingCharge } = addToCart.shippingMethod
    //const { building, road, area, block } = addToCart.address
    //let address = addToCart.address
    const address = JSON.parse(localStorage.getItem('address'))

    let time = Date.now() / 1000
    const user = useSelector((state) => state.user)
    const { id, email, username } = user.userInfo;
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    let uniqueVendors
/*     if (cartItems.length == 1) {
        uniqueVendors = [cartItems[0].vendor]
    } 
    else {
 */        uniqueVendors = [...
        new Set(cartItems.map(
            (item) => {
                ////console.log(`from shippingjsx each vendor ${item.vendor}`)
                return item.vendor
            })
        )];
    //}
    ////console.log(`unique vendors${uniqueVendors}`)
    let shippingPerOrder = shippingCharge / uniqueVendors.length;
    // const createOrder = useSelector((state) => state.createOrder)
    //const { order, success, error } = createOrder


    let perVendor = uniqueVendors.map((vendor) => {
        return cartItems.filter((item) => item.vendor == vendor)

    })
    ////console.log(`pervendor order${JSON.stringify(perVendor)}`)
    ////console.log(`unique vendors length${uniqueVendors.length}`)
    useEffect(() => {
        console.log('we reached use effect');
        for (const order of perVendor) {
            const itemsTotalPerVendor = order.reduce((acc, item) => acc + item.price * item.quantity, 0)
            let concatObjects = [];
            concatObjects.push(order);
            let finalFilter = concatObjects.filter((item) => item.vendor_id == order.vendor_id)

            console.log('order per vendor ', order);
            dispatch(createOrderAction({
                vendor_id: order[0].vendor_id,
                items_json: JSON.stringify(finalFilter[0]),
                time: time,
                user_id: id,
                address_json: JSON.stringify(address),
                grand_price: shippingPerOrder + itemsTotalPerVendor,
                shipping_cost: shippingPerOrder,
                user_email: email,
                delivery_option: shippingMethod,
                remarks: address.memo,
                items_total: itemsTotalPerVendor,
                status: 'pending_merchant_response',
                payment_method: 'online_payment',
                payment_status: 'success',
                transaction_id: transId,
            }))

        }
        perVendor.map((order) => {
            /*                 const itemsTotalPerVendor = order.reduce((acc, item) => acc + item.price * item.quantity, 0)
                            let concatObjects=[];
                            concatObjects.push(order);
                            let finalFilter = concatObjects.filter((item) => item.vendor_id==order.vendor_id)
            
                    
                            dispatch(createOrderAction({
                                vendor_id: order[0].vendor_id,
                                items_json: JSON.stringify(finalFilter[0]),
                                time: time,
                                user_id: id,
                                address_json: JSON.stringify(address),
                                grand_price: shippingPerOrder + itemsTotalPerVendor,
                                shipping_cost: shippingPerOrder,
                                user_email: email,
                                delivery_option: shippingMethod,
                                remarks: address.memo,
                                items_total: itemsTotalPerVendor,
                                status: 'pending_merchant_response',
                                payment_method: 'online_payment',
                                payment_status: 'success',
                                transaction_id: transId,
                            }))
             */

        }
        )
        navigate('/thank-you')
        ////console.log(`does it reach after thank you`)
    }, [dispatch, navigate])






    return (
        <>
            <Navigation>        </Navigation>

            <h2>Payment is successfully received</h2>
            <h5>Ref {transId}</h5>
        </>
    );



}

export default PaymentSuccess;