import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Announcement.css'

function Announcement() {
    const [shClosed, setShClosed] = useState(false)
    const onClose = () => {
        setShClosed(true)
    }

    return (
        <>
            <div class={shClosed ? "SiteHeader-notice_bar SiteHeader-notice_bar__closed" : "SiteHeader-notice_bar"}>
                <div class="SiteHeader-notice">
                    <p class="SiteHeader-notice-text">
                        Free shipping on orders over BD<span class="threshold">15</span> <span class="amount"></span>
                    </p>
                    <span onClick={onClose} class="material-symbols-outlined close-notice-bar">
                        close
                    </span>                        </div>
            </div>
        </>
    )
}

export default Announcement