/* second step of redux */
export const productsListReducer = (state = { products: [] }, action) => {
  /* reducers are functions that take the actions process them and store the results in the store, and thevresults provided are the history of the products[] and the action of the new requested product */
  /* initial state is empty array for products, and action is to dispatch the payload to the state */
  switch (action.type) {
    /* check for the three action types 1 request to the server 2 response 3 error and do something with each type, it is set by hooks on productAction.js */
    case 'PRODUCT_LIST_REQUEST':
      return { loading: true, products: [] };
    //this case is set before the request to the backend, and we set the loading to true here and products to empty
    case 'PRODUCT_LIST_SUCCESS':
      //////console.log(`from productReducer success ${JSON.stringify(action.payload)}`);
      /* from productReducer success [{"id":280239,"title":"Atyab al marshoud-MARSHOUD 3 EDP 30ml U","vendor":"Beauty Touch","price":11.4712},{"id":290747,"title":"Large Rainbow - Jenga","vendor":"Gamer","price":2.9},{"id":342450,"title":"Cerave-Moisturizing Cream, For Normal to Dry Skin (56 ml)","vendor":"Beauty Touch","price":3.515},{"id":272230,"title":"Daisy Duck Pink Airpod case","vendor":"Be Unique","price":2},{"id":347357,"title":"وردة مع شريطة و بطاقة بكتابة للأب","vendor":"spring express gifts ","price":1.5},{"id":172844,"title":"Turkish coffee set tropic 6 pcs","vendor":"Oda Decor ","price":49.5},{"id":262935,"title":"Taekwondo Adidas Black Belts","vendor":"Sport to Door","price":15},{"id":280071,"title":"Albar w Almazyoon 2015","vendor":"Bahraini Deal","price":2},{"id":345088,"title":"JBL FLIP 5","vendor":"Noora Janahi","price":45},{"id":340127,"title":"Korkmaz Astra Low Casserole 26x10 Cm / 5.3l. A-1907","vendor":"F.F.WATCH COMPANY W.L.L.","price":14.55}] */
      return { loading: false, products: action.payload };
    // this case is set after we get success response. and we set the loading to false here and products to the response.data
    case 'PRODUCT_LIST_FAIL':
     // ////console.log(`from productReducer reject ${JSON.stringify(action.payload)}`);

      return { loading: false, error: [action.payload] };
    // this case is set after we get reject response. and we set the loading to false here and products to the error
    default:
      return state;
    /* default return the initial state */
  }
};
