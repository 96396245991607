
/* deploy the app
https://www.youtube.com/watch?v=SuMRI_A8umQ
*/
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "./routes/navigation/navigation";
import "./App.css";
import Home from "./routes/home/homepage.jsx";
import SignIn from "./routes/signIn/signIn.jsx";
import SignUp from "./routes/signUp/signUp.jsx";
import ProductPage from "./routes/product/ProductPage";
import Address from "./routes/address/Address";
import { AuthContext } from "./contexts/AuthContext";
import Cart from "./routes/cart/Cart";
import GoogleAuth from "./routes/signIn/GoogleAuth";
import Shipping from "./routes/shipping/Shipping";

import Location from "./routes/Location/Location";
import Payment from "./routes/payment/Payment";
import SelectAddress from "./routes/selectAddress/SelectAddress";
import EditLocation from "./routes/editLocation/EditLocation";
import EditAddress from "./routes/editAddress/EditAddress";
import ThankYou from "./routes/thankYou/ThankYou";
import PaymentSuccess from "./routes/paymentSuccess/PaymentSuccess";
import PaymentSuccessDashboard from "./routes/paymentSuccess/PaymentSuccessDashboard";

import OrderMsg from "./routes/orderMsg/OrderMsg";
import PaymentError from "./routes/paymentError/PaymentError";
import BtDelivery from "./routes/DeliveryVendors/BtDelivery";
import BzDelivery from "./routes/DeliveryVendors/BzDelivery";
import AppShipping from "./routes/app/Shipping";
import AppPayment from "./routes/appPayment/Payment";
import AppThankYou from "./routes/appThankYou/ThankYou";
import ZadDelivery from "./routes/DeliveryVendors/zadDelivery";
import PartyoDelivery from "./routes/DeliveryVendors/partyoDelivery";
import CredimaxGateway from "./routes/payment/CredimaxGateway";
import PaymentDashboard from "./routes/paymentSuccess/PaymentDashboard";

function App() {
  /*   const [userInfoState,setUserInfo]=useState(false)
   */ return (
    /*     <AuthContext.Provider value={{userInfoState,setUserInfo}}>
     */
    /* if we put the prop at this level it will be accessable through all children to prevent the drilling, and we will pass to this prop, the states the children then will setInfostate to rerender the whooe components */
    <Routes>
{/*       <Route path="/" element={<Navigation />}>
 */}        {/* this is like in node route.get('/', userController.viewProfile) which will send req and will get res.render. except that in react all the pages will be sent once at the beginning, and then with these routes it will render pieces of them. but we still need servers for apis and sensetive info that requires api keys to connect to */}
        {/* this will wrap all since its always there */}
        
        <Route index element={<Home />} />
        {/* index is use the above route */}
        <Route path="product/:id" element={<ProductPage />} />
        <Route path="main-cart" element={<Cart />} />
        <Route path="cart/:id" element={<Cart />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="google-auth" element={<GoogleAuth />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="shipping" element={<Shipping />} />
        <Route path="location" element={<Location />} />
        <Route path="address" element={<Address />} />
        <Route path="payment" element={<Payment />} />
        <Route path="credimax-gateway" element={<CredimaxGateway />} />

        <Route path="select-address" element={<SelectAddress />} />
        <Route path="edit-location" element={<EditLocation />} />
        <Route path="edit-address" element={<EditAddress />} />
        <Route path="thank-you" element={<ThankYou />} />
        <Route path="/app/thank-you" element={<AppThankYou />} />

        <Route path="epayment-success" element={<PaymentSuccess />} />
        <Route path="epayment-success-dashboard/:orderId/:transactionId" element={<PaymentSuccessDashboard />} />
        <Route path="epayment-dashboard/:orderId" element={<PaymentDashboard />} />

        <Route path="epayment-error" element={<PaymentError />} />
        <Route path="bt-delivery" element={<BtDelivery />} />
        <Route path="bz-delivery" element={<BzDelivery />} />
        <Route path="zad-delivery" element={<ZadDelivery />} />
        <Route path="partyo-delivery" element={<PartyoDelivery />} />

        <Route path="app" element={< AppShipping/>} />
        <Route path="app-payment" element={< AppPayment/>} />

        <Route path="msg/:rand/:orderId" element={<OrderMsg />} />


    </Routes>
    /*     </AuthContext.Provider>
     */ //this will go inside the root div in index
  );
}

export default App;
