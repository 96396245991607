/* second step of redux */
export const addToCartReducer = (
  state = { cartItems: [], address: {}, shippingMethod: {},selectAddress:[] },
  action
) => {
  if (action.type == "ADD_TO_CART") {
    return { ...state, cartItems: action.payload };
  }
  else if (action.type == "ADD_TO_CATs") {
    //////console.log(`from add to cart Reducer success ${JSON.stringify(action.payload)}`);
    const item = action.payload;
    const itemExists = state.cartItems.find((product) => {
      return product.id == item.id;
      /* do we have match between the added item and any item in the cart */
    });
    if (itemExists) {
      return {
        ...state,
        cartItems: state.cartItems.map((x) =>
          x.id === itemExists.id ? item : x
        ),
        /* keep the old state and make a copy of it and set the duplicate item to the new one in case the price changed*/
      };
    } else {
      return {
        ...state,
        cartItems: [...state.cartItems, item],
        /* we shouldnt mutate the previous state in reducers, but make a copy of it and update tha copy */
      };
    }
  } else if (action.type == "REMOVE_FROM_CART") {
    return {
      ...state,
      cartItems: state.cartItems.filter((item) => {
        return item.id != action.payload;
      }),
    };
  } else if (action.type == "SHIPPING_METHOD") {
    return { ...state, shippingMethod: action.payload };
  } else if (action.type == "LOCATION") {
    return { ...state, location: action.payload };
  } else if (action.type == "SAVE_ADDRESS") {
    return { ...state, address: action.payload };
  } else if(action.type=="ADDRESSES_REQUEST"){
    return { ...state, loading:true, selectAddress: [] };
  } else if(action.type=="ADDRESSES_SUCCESS"){
    return { ...state, loading:false, selectAddress: [action.payload] };
  } else if(action.type=="ADDRESSES_FAIL"){
    return { ...state, loading:false, error: [action.payload] };
  }  else if(action.type=="GET_CART_REQUEST"){
    return { ...state, loading:true, cartItems: [] }
  }
  else if (action.type == "GET_CART_SUCCESS") {
      return { ...state,loading:false, cartItems: action.payload };
  } 
  else if (action.type == "RECORD_CART_ID") {
    return { ...state, cartId: action.payload };
} 

  else if(action.type=="GET_CART_FAIL"){
    return { ...state, loading:false, error: [action.payload] };

  }
  else {
    return state;
  }
};
