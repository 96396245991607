import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import './CouponCode.css';
import { Form } from 'react-bootstrap'
import axios from 'axios';

const CouponCode = ({ itemsTotalPrice, itemsJsonAndTotalPerVendor, setAppliedCouponitemsJsonAndTotalPerVendor }) => {

    const [couponCode, setCouponCode] = useState('');
    const [coupon, setCoupon] = useState(null);
    const [couponError, setcouponError] = useState(null);
    const [discountedPrice, setDiscountedPrice] = useState(null);

    const grandAmount = itemsJsonAndTotalPerVendor.reduce((acc, item) => acc + item.itemsTotal, 0)
    const onCoupon = async (event) => {
        event.preventDefault();
        //  alert(couponCode)

        try {
            // const response = await axios.post(`https://feehla.com/api/v1/checkout/review`,
            const response = await axios.post(`https://feehla-checkout-api.herokuapp.com/checkout/coupon`,
                {
                    //"shopping_cart":JSON.stringify(cart),
                    // "coupon_code":couponCode,
                    couponCode: couponCode,
                }
            );
            const coupon = response.data.coupon[0]
            let discountAmount = 0
            let clear = false
            for (let itemsJsonAndTotal of itemsJsonAndTotalPerVendor) {

                console.log('match itemsjson with code');
                console.dir(itemsJsonAndTotal)
                console.dir(response.data)


                if (response.data.coupon[0].vendor_id === 0) {
                    if (response.data.coupon[0].minimum_total_amount <= grandAmount) {
                        console.log('grand amount ', grandAmount);
                        console.log('minimum amount ', response.data.coupon[0].minimum_total_amount);

                        if (coupon.discount_percentage > 0) {
                            discountAmount = itemsJsonAndTotal.itemsTotal * (coupon.discount_percentage / 100)
                            const discountedAmount = itemsJsonAndTotal.itemsTotal - discountAmount
                            console.log('discount amount ', discountedAmount);
                            itemsJsonAndTotal.itemsTotal = discountedAmount
                            setDiscountedPrice(discountedAmount)

                            clear = true
                        } else if (coupon.discount_amount > 0) {
                            console.log(' discount amount more than 0');
                            discountAmount = coupon.discount_amount
                            const discountedAmount = itemsJsonAndTotal.itemsTotal - (discountAmount / itemsJsonAndTotalPerVendor.length)
                            console.log('discount amount ', discountedAmount);
                            itemsJsonAndTotal.itemsTotal = discountedAmount
                            setDiscountedPrice(discountedAmount)

                            clear = true


                        }
                    } else {
                        setcouponError(`Minimum Order Amount from the certain vendor should be ${response.data.coupon[0].minimum_total_amount}`);
                    }


                } else {
                    if (response.data.coupon[0].minimum_total_amount <= itemsJsonAndTotal.itemsTotal) {

                        if (itemsJsonAndTotal.itemsJson[0].vendor_id === response.data.coupon[0].vendor_id) {
                            console.log('vendor match ', itemsJsonAndTotal.itemsJson[0].vendor_id);
                            if (coupon.discount_percentage > 0) {
                                discountAmount = itemsJsonAndTotal.itemsTotal * (coupon.discount_percentage / 100)
                                const discountedAmount = itemsJsonAndTotal.itemsTotal - discountAmount
                                console.log('discount amount ', discountedAmount);
                                itemsJsonAndTotal.itemsTotal = discountedAmount
                                setDiscountedPrice(discountedAmount)

                                clear = true

                            } else if (coupon.discount_amount > 0) {
                                discountAmount = coupon.discount_amount
                                const discountedAmount = itemsJsonAndTotal.itemsTotal - discountAmount
                                console.log('discount amount ', discountedAmount);
                                itemsJsonAndTotal.itemsTotal = discountedAmount
                                setDiscountedPrice(discountedAmount)

                                clear = true

                            }
                        } else {
                            setcouponError('Invalid Code');
                        }
                    } else {
                        setcouponError(`Minimum Order Amount from the certain vendor should be ${response.data.coupon[0].minimum_total_amount}`);
                    }



                }




            }
            console.log('coupon api response ', response.data);
            setCoupon(coupon)
            //setCoupon(data);
            console.log('edited json before sending');
            console.dir(itemsJsonAndTotalPerVendor)
            if (clear) {
                console.log(' should set the coupon');
                setAppliedCouponitemsJsonAndTotalPerVendor(itemsJsonAndTotalPerVendor, discountAmount, coupon.code)
            }


        } catch (err) {
            setcouponError('Invalid Code');
        }
    };



    return (
        <div className='methodP'>
            <h2>Coupon Code</h2>
            <form onSubmit={onCoupon} className='giftForm'>

                <input type="text" value={couponCode} onChange={e => setCouponCode(e.target.value)} className='giftInput' />

                <button disabled={discountedPrice} type="submit" className='giftBtn'><span class="material-symbols-outlined">
                    arrow_forward
                </span></button>

            </form>
            {couponError && <p>{couponError}</p>}
            {coupon && (
                <div className=''>
                    <div className='tagContainer couponConfirmationContainer'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 473" width='20px' height='20px' fill='grey'><path d="M345 39.1L472.8 168.4c52.4 53 52.4 138.2 0 191.2L360.8 472.9c-9.3 9.4-24.5 9.5-33.9 .2s-9.5-24.5-.2-33.9L438.6 325.9c33.9-34.3 33.9-89.4 0-123.7L310.9 72.9c-9.3-9.4-9.2-24.6 .2-33.9s24.6-9.2 33.9 .2zM0 229.5V80C0 53.5 21.5 32 48 32H197.5c17 0 33.3 6.7 45.3 18.7l168 168c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0l-168-168C6.7 262.7 0 246.5 0 229.5zM144 144c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z" /></svg>
                        <span >
                            {'   ' + coupon.code}
                        </span>
                    </div>
                </div>

            )}

        </div>

    )
}

export default CouponCode