import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../payment/Payment.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createOrderAction, deductQtysAction } from '../../actions/orderActions';
import { useEffect, useState } from 'react';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import LoadingSpinner from '../../components/Spinner/Spinner';
import { useLocation } from 'react-router-dom';

const CredimaxGateway = () => {
    let navigate = useNavigate()
    const location = useLocation();
    const sessionId = location.state.id;
    console.log(sessionId);
    const script = document.createElement('script');
    script.src = 'https://credimax.gateway.mastercard.com/static/checkout/checkout.min.js';
    script.setAttribute('data-error', 'errorCallback');
    script.setAttribute('data-cancel', 'cancelCallback');
    script.setAttribute( 'data-complete',"completeCallback")
    script.type = 'text/javascript';
   
    document.head.appendChild(script);
    useEffect(() => {
     
        script.onload = configureCheckout;
        window.errorCallback = (error) => {
            console.log(JSON.stringify(error));
        };

        window.cancelCallback = () => {
            console.log('Payment cancelled');
        };
        function configureCheckout() {
            window.Checkout.configure({
              session: {
                id: `${sessionId}`,
              },
          
            });
          }
        //   window.addEventListener('load', () => {
        //     window.Checkout.showEmbeddedPage('#embed-target');
        //   });       
           function completeCallback(resultIndicator, sessionVersion) {
            //handle payment completion
            alert(resultIndicator)
        }
     
    }, []);



    return (
        <>
            <Navigation>        </Navigation>
            <OrderSummary></OrderSummary>


            <div className='content'>
                <div>


                    <div className='wrap'>
                        <div className="main">
                            <MainHeader></MainHeader>



                            <div>
                                <div id="embed-target" />
                                {/* {()=>window.Checkout.showEmbeddedPage('#embed-target')} */}
                                <input
                                    type="button"
                                    value="Pay with Embedded Page"
                                    onClick={() => window.Checkout.showEmbeddedPage('#embed-target')}
                                />
                                <input type="button" value="Pay with Payment Page" onClick={() => window.Checkout.showPaymentPage()} />
                            </div>


                        </div>
                        <SideBarSummary></SideBarSummary>

                    </div>


                </div>
            </div>

        </>
    );



}

export default CredimaxGateway;