import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../thankYou/thankYou.css'
import { saveAddressAction, selectAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import { useEffect, useState } from "react";
import MapComponent from '../../components/mapSnippet/MapComponent';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary';
const ThankYou = () => {
    /* the listing will be similar to productsList */
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const createOrder = useSelector((state) => state.createOrder)
    const { order, success, error, loading } = createOrder
    localStorage.removeItem('order')
    // localStorage.removeItem('cartItems')
    localStorage.clear()

    const getDate = (iso) => {
        var dt = new Date(iso * 1000);
        dt.setDate(dt.getDate() + 3)
        var expected = new Date(iso * 1000)
        expected.setDate(expected.getDate() + 1)

            .toString().slice(0, 10);
        const deliveryTime = `Expected delivery between \n ${expected.toString().slice(0, 10)} and ${dt.toString().slice(0, 10)}`
        return deliveryTime;
    }

    const getExpress = (iso) => {
        var dt = new Date(iso * 1000);
        dt.setHours(dt.getHours() + 2);
        const orderDate = `${dt.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`

        const deliveryTime = `Expected before ${orderDate}`
        return deliveryTime;
    }
    /* useEffect(() => {
      
    
    }, [success])
     */
    return (

        <div >
            {loading ? <h3>loading...</h3> : error ? <h2>{error}</h2> : (
                <>
                    <Navigation announcement={'No'}>        </Navigation>


                    <div className='content'>

                        <Formik
                        >{() => (
                            /* we get those from formik props */
                            <Form className='wrap'>
                                <div className="main">
                                    <MainHeader announcement={'No'}></MainHeader>

                                    <div className='method'>
                                        <h1 className='thankyouMsg'>{order[0].payment_status == 'success' ? 'Allah yeghneekom!' : 'Thank you!'
                                        } </h1>
                                        <p className='preparing'>
                                            we are preparing your order. Our driver will whatsapp you before going out.
                                        </p>
                                        {order ? (order.map((each, index) => (
                                            <>
                                                {/*                                         Event snippet for purchase conversion page 
 */}
                                                <script>
                                                    {window.gtag('event', 'conversion', {
                                                        'send_to': 'AW-969130814/TeEoCJbipKMBEL6Gj84D',
                                                        'value': each.grand_price/.377,
                                                        'currency': 'USD',
                                                        'transaction_id': `${each.id}`
                                                    }

                                                    )}
                                                    {window.snaptr('track', 'PURCHASE', {
                                                        'currency': 'USD', 'price': each.grand_price/.377,
                                                        'transaction_id': `${each.id}`
                                                    })}
                                                </script>

                                                <div key={index} className="tableWrapper">
                                                    <table className='productTable'>
                                                        <tbody>


                                                            <tr key={index} >
                                                                
                                                                <td >
                                                                    <div class="product-picS">

                                                                        <img src={each.thumb_url!==null?(each.thumb_url.split('/')[0] !== 'uploads'? ('https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/' + each.thumb_url.split('/images-300x300/')[1]):('https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/' + each.thumb_url.split('/images_thumbs/')[1])):''}alt="" 
                                                                                                                                             />

{/*                                                                          {each.thumb_url!==null?('https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/' + each.thumb_url.split('/images_thumbs/')[1]):''} alt="" 
 */}                                                                         {/*                                                                         ////console.log(`https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/u2f/images_thumbs/${each.thumb_url.split('/images_thumbs/')[1]}`)
 */}                                                                    </div>
                                                                </td>

                                                                <th className='productDescription'>

                                                                    <span className="product-titleT">

                                                                        <div>
                                                                            Order Id {each.id}
                                                                        </div>
                                                                    </span>
                                                                    {each.payment_status === 'success' ? <span className='paymentStatus'>Paid</span> : null}
                                                                    <span className='status'>{each.status}</span>

                                                                    <span className='time'>{each.delivery_option == 'express' ? getExpress(each.time) : getDate(each.time)}</span>

                                                                </th>
                                                                <td>                                            <div className='chevronR' >

                                                                <Link to = {'/msg'+ each.email_url2.split('msg')[1]} >

                                                                    <span class="material-symbols-outlined">
                                                                        chevron_right
                                                                    </span>   
                                                                    </Link>
                                             </div>

                                                                </td>

                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>

                                            </>))) : <div>There are no orders</div>}
                                    </div>
                                </div>
                            </Form>
                        )}

                        </Formik>
                    </div>
                </>
            )}

        </div>
    );



}

export default ThankYou;

