import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { addToCartAction } from "../../actions/addToCartAction";
import { removeFromCartAction } from "../../actions/addToCartAction";

import { Form } from 'react-bootstrap'
import '../cart/cart.css'
import SiteNavigation from '../siteNavigation/SiteNavigation';
const Cart = () => {
  let productId = useParams().id
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const addToCart = useSelector((state) => state.addToCart)
  const cartItems = addToCart.cartItems
  ////console.log(`from cart.jsx ${JSON.stringify(cartItems)}`)
  const user = useSelector((state) => state.user)
  const username = user.userInfo;

  useEffect(() => {
    if (productId) {
      /* if there is a productid in the url means we came here through add to cart button not through view cart logo so we want to start add to cart func */
      let qty = 1;
      dispatch(addToCartAction(productId, qty))
    }
  }, [dispatch, productId])
  const deleteItem = (id) => {
    dispatch(removeFromCartAction(id))
  }
  const proceedCheckout = () => {
    username ? navigate('/shipping') : navigate('/sign-in?redirect=shipping')
  }
  return (
    <>
      <SiteNavigation></SiteNavigation>
      <div className="content cart">
        <div className="cart-list">
          <div className="actual-listing">

            {cartItems.length === 0 ? (
              <>
                <div>Cart is empty. </div>
                <Link to="/">Go Shopping</Link>
              </>)

              :
              (cartItems.map((item) => {
                /*                 <!------ item begins ------>
                 */
                return (
                  <>
                    <div className="productO">
                      <div className="product-body">
                        <div class="product-pic">
                          {item.image.split('/')[0] !== '[aws_images_bucket]' ? (<img src={'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/' + item.image.split('/images/')[1]} alt="" />)
                            : (<img src={'https://s3.us-east-2.amazonaws.com/feehla-images-uploads/images-300x300/' + item.image.split('/images/')[1]} alt="" />)}

                        </div>
                        <div className="product-info" >
                          <div className="product-title">

                            <Link to={`/product/${item.id}`}>
                              {item.title}
                            </Link>
                          </div>
                          <div className='price'>


                            <h2 className="currency">BHD</h2>
                            <h2 className="bd">{item.price}</h2>
                          </div>
                        </div>

                      </div>
                      <div className="cart-footer">
                        <Form.Control className='qty-select'
                          as='select'
                          value={item.qty}
                          onChange={(e) => {
                            dispatch(addToCartAction(item.id, Number(e.target.value)))
                            /* store the qty for this item id in redux and fire rerender */
                          }}
                        >
                          {[...Array(item.stock).keys()].map((x) => (
                            /* array(5) will make an array of 5 empty values, and the key of each value is the index. if the stock is 6 we will get 0,1,2,3,4,5,6 */
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                        {console.log(`from cart.jsxlets see the array spread keys log for=> ${item.title} ${[...Array(item.stock).keys()]}`)}
                        {/* from cart.jsxlets see the array spread keys log for=> Large Rainbow - Jenga 0,1,2,3,4,5,6 */}
                        <button className="delete-cart" onClick={() => {
                          deleteItem(item.id)
                        }}>
                          Delete
                        </button>

                      </div>

                    </div>

                  </>
                )
              }))}
          </div>
          <div className="cart-action">
            <h3>

              Subtotal <span id='totalQty'>
                {cartItems.reduce((a, c) => a + c.qty, 0)}
              </span> item(s)

              <div className="price">
                <h2 className="currency">BHD</h2>
                <h2 id='totalPrice' className="bdCart bd">
                  {cartItems.reduce((a, c) => a + c.price * c.qty, 0).toString().split('.')[0]}
                </h2>
                <h2 id='totalPrice' className="fils filsCart">
                  {cartItems.reduce((a, c) => a + c.price * c.qty, 0).toString().split('.')[1] == 'undefined' ? '00' : cartItems.reduce((a, c) => a + c.price * c.qty, 0).toString().split('.')[1] + '0'}
                </h2>
              </div>
            </h3>
            <button onClick={proceedCheckout} id="checkout-button" disabled={cartItems.length === 0} className="primary fw">            Proceed to Checkout

            </button>

          </div>

        </div>
      </div>
    </>
  )

}



export default Cart