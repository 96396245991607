export const ExpressOn = (timings2) => {
    //const timings = {"sunday":[{"id":"k9j2nlcy","open":"1000","close":"2100","isOpen":true}],"monday":[{"open":"1000","close":"2100","id":"5ca5578b0c5d1","isOpen":true}],"tuesday":[{"open":"1000","close":"2100","id":"5ca5578b0c5d8","isOpen":true}],"wednesday":[{"open":"1000","close":"2100","id":"5ca5578b0c5df","isOpen":true}],"thursday":[{"open":"1000","close":"2100","id":"5ca5578b0c5e6","isOpen":true}],"friday":[{"open":"1000","close":"2100","id":"5ca5578b0c5ec","isOpen":true}],"saturday":[{"open":"1000","close":"2100","id":"5ca5578b0c5f8","isOpen":true}]}
    let timings = JSON.parse(timings2)
    if(timings.monday[0].open=='24hrs'){
     timings = {"sunday":[{"id":"k9j2nlcy","open":"1000","close":"2100","isOpen":true}],"monday":[{"open":"1000","close":"2100","id":"5ca5578b0c5d1","isOpen":true}],"tuesday":[{"open":"1000","close":"2100","id":"5ca5578b0c5d8","isOpen":true}],"wednesday":[{"open":"1000","close":"2100","id":"5ca5578b0c5df","isOpen":true}],"thursday":[{"open":"1000","close":"2100","id":"5ca5578b0c5e6","isOpen":true}],"friday":[{"open":"1000","close":"2100","id":"5ca5578b0c5ec","isOpen":true}],"saturday":[{"open":"1000","close":"2100","id":"5ca5578b0c5f8","isOpen":true}]}
}
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    console.log('timings', timings);
    function padTo2Digits(num) {
        return String(num).padStart(2, '0');
    }
    const date = new Date();
    let day = days[date.getDay()];
    console.log('day ', day);

    // var date = new Date();
    var hour = padTo2Digits(date.getHours())
    var minutes = padTo2Digits(date.getMinutes())
    var hourminute = hour + minutes
    let open = timings[day][0].open
    const close = timings[day][0].close
    console.log('day ' + day + 'current time ' + hourminute + 'shop opens ' + open + 'shop closes ' + close);
    //check if its sunday or saturday
    // const dayd=JSON.parse(day)
    console.log(timings[day][0].isOpen);
    if ((!timings['friday'][0].isOpen && !timings['saturday'][0].isOpen)&&(day==='friday'||day==='saturday')) {
        return ("is currently closed on Friday & Saturday.")
        
        // check if its between 9am and 11pm (inclusive)
    } else if ((!timings['friday'][0].isOpen)&&(day==='friday')) {
            return ("is currently closed on Friday")
        // check if its between 9am and 11pm (inclusive)
    } else if (Number(hourminute) < Number(open) || Number(hourminute) > Number(close)) {
        console.log('we should reach here');
        const dtClose = new Date();
        const hourClosed = close.slice(0, 2)
        const minutesClosed = close.slice(2, 2)
        dtClose.setHours(hourClosed, minutesClosed)
       const finalClose= dtClose.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        console.log('fuck ', finalClose);
        return ('closed at ' + finalClose);
    } else {
        console.log('Shop is open')
        return('')
    }




}