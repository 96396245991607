
import { initializeApp } from 'firebase/app';
import axios from 'axios';
/* the instance of the app to configure it with your credentials */
import {getAuth,signInWithRedirect,signInWithPopup,GoogleAuthProvider,FacebookAuthProvider,signInAnonymously,onAuthStateChanged} from 'firebase/auth';
/* the instances of auth methods */
import { Link , useNavigate } from 'react-router-dom'
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBnf0FISfy9OxvfLef5AIryDVE5hMhWf6g",
  authDomain: "auth.feehla.com",
  databaseURL: "https://enaseej-383fe.firebaseio.com",
  projectId: "enaseej-383fe",
  storageBucket: "enaseej-383fe.appspot.com",
  messagingSenderId: "229248064552",
  appId: "1:229248064552:web:e96e9079874a83de5da91d",
  measurementId: "G-VB62BF5KFH"};

///practice
/* const firebaseConfig = {
    apiKey: "AIzaSyAAIrHFQYmQ_dnuz04-hrAOmItfrAYi5-Q",
    authDomain: "fir-auth-4225d.firebaseapp.com",
    projectId: "fir-auth-4225d",
    storageBucket: "fir-auth-4225d.appspot.com",
    messagingSenderId: "263508254099",
    appId: "1:263508254099:web:ef26acbd86805604899c66"
  };
 */  /* we get the keys from firebase website */
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  /* put the configs in  the app instance to connect it */

/* initiate a class of google provider because class has many options */
/* tell the class to always prompt select account */

/* export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  ////console.log(userAuth);
};
 */
export const auth = getAuth();
/* no need for class like provider this is one instance of auth for all autjentications its same */
export const signInWithGooglePopup = () => {
  //console.log('signin with google started');
 const provider = new GoogleAuthProvider();
 provider.setCustomParameters({ prompt: 'select_account',});

 return signInWithPopup(auth, provider);}
/* we export this variable to signin react button function which return a function that cooks auth and provider which will return the authenticated user info with access token saying that yes i issued the first token and it matches and this user user and password is true with me  and fire it on click button in sign in page and send it in the below function to send it to the backend */
export const signInWithFacebookPopup = () => {
  ////console.log('signin with google started');
 const provider = new FacebookAuthProvider();
 provider.setCustomParameters({'display': 'popup'});

 return signInWithPopup(auth, provider);}

export const checkUser = async (user) => {

/* it will send object to the backend on specific route, the objrct contains the user info that we will get from the signinwithgooglepopup to check if the user doesnt exist insert new user in db */
}

export const signInWithAnPopup = () => {
  return   signInAnonymously(auth)

/*   signInAnonymously(auth)
  .then((response) => {
    //console.log('signed in', JSON.stringify(response));
    return response

  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
    return errorMessage
  });
 *//*   onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      return user;
      // ...
    } else {
      //console.log('user signedout');
      // User is signed out
      // ...
    }
   });
 */

}

