/* create order from state */
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { Formik, Field, Form } from 'formik';
import '../paymentSuccess/PaymentSuccess.css'
import { saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import sadad from '../../img/sadad.png'
import { createOrderAction } from '../../actions/orderActions';
import { useEffect } from 'react';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';

const PaymentError = () => {

    return (
        <div className='content'>

        <div className='wrap'>

        <div className="main">
            <MainHeader></MainHeader>

            <div className='method'>

                <Navigation>        </Navigation>
                <h1 className='thankyouMsg'>There was an error during processing the payment, please try again or contact the customer support </h1>

            </div>

        </div>
        </div>
        </div>
    )


}

export default PaymentError;