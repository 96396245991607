import React from 'react';
import './homepage.css';
import ProductsListing from '../../components/products-listing/products-listing.jsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { productAction } from '../../actions/productActions'
import ProductComponent from '../../components/product/ProductComponoent'
import { Link } from 'react-router-dom'
import SiteNavigation from '../siteNavigation/SiteNavigation';



const Home = () => {
  const dispatch = useDispatch()
  const productList = useSelector((state) => {
   // ////console.log(`from homepage what is state ${JSON.stringify(state)}`)
/* from homepage what is state {"productList":{"loading":false,"products":[{"id":280239,"title":"Atyab al marshoud-MARSHOUD 3 EDP 30ml U","vendor":"Beauty Touch","price":11.4712},{"id":290747,"title":"Large Rainbow - Jenga","vendor":"Gamer","price":2.9},{"id":342450,"title":"Cerave-Moisturizing Cream, For Normal to Dry Skin (56 ml)","vendor":"Beauty Touch","price":3.515},{"id":272230,"title":"Daisy Duck Pink Airpod case","vendor":"Be Unique","price":2},{"id":347357,"title":"وردة مع شريطة و بطاقة بكتابة للأب","vendor":"spring express gifts ","price":1.5},{"id":172844,"title":"Turkish coffee set tropic 6 pcs","vendor":"Oda Decor ","price":49.5},{"id":262935,"title":"Taekwondo Adidas Black Belts","vendor":"Sport to Door","price":15},{"id":280071,"title":"Albar w Almazyoon 2015","vendor":"Bahraini Deal","price":2},{"id":345088,"title":"JBL FLIP 5","vendor":"Noora Janahi","price":45},{"id":340127,"title":"Korkmaz Astra Low Casserole 26x10 Cm / 5.3l. A-1907","vendor":"F.F.WATCH COMPANY W.L.L.","price":14.55}]},"productDetails":{"product":{}},"addToCart":{"cartItems":[]}} */
    return state.productList

  })
  /* select the product state from the store */
  const { loading, error, products } = productList

  /* here we fucking finally get the store products from the product list in the store  */
  //const [products, setProducts] = useState([]);
  /* usestate is to use it inside functional component unlike the traditional class component, products is the state which will hold the info we want to store and setProducts is the function that set the first products, and the initial value is inside the empty array useState because the producrs are in array format, if we want object or text we can use empty '' or {}. set state will rerender the page  */
  /* define products as an em0ty array in useState. and whenever setProducts is called which will set the products below from data.response, the useState will react to the dtate changed and will rerender tge below return with the filled product array, unlike my previous attempt which passed an empty array */
  /* let products=[];*/
  useEffect(() => {
    dispatch(productAction())
    /* basically since react fakely use routes , render for each dispatch change */

    /*
   use effect is replacement of on mounted. which will run once here after the initial render, because of the below empty array. and it is used only for api requests because the page will initaially render empty array before we got a response from the backend. if u   */    //}).catch()
  }, [dispatch])
  /* for each change in dispatch, rerender, hence we get three states in the redux console and the page in fact will reload three times before fetching the data */

  return (
    <div className='homepage'>
      <SiteNavigation></SiteNavigation>
      {/*           {loading?<h3>loading...</h3>:error?<h2>{error}</h2>:<ProductsListing  products={products} />}
 */}
      {loading ? <h3>loading...</h3> : error ? <h2>{error}</h2> : (


        <div className='listing'>
          <div className='products-listing'>
            {products.map((product) => (
              /* here in the success i get it in two arrays but in req one so i had to check for length first */
              <Link to={`product/${product.id}`} key={product.id}>

                <ProductComponent key={product.id} product={product} />
              </Link>
            )) }
          </div>
        </div>
      )}

    </div>
    //pass products as a prop to productslisting
    //this will go into root div by App
  )

}
export default Home;