/* first step of redux */
import axios from "axios";
export const addToCartAction =
  (cart) => async (dispatch, getState) => {

    dispatch({
      type: "ADD_TO_CART",
      payload: cart
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().addToCart.cartItems)
    );
    /* we save the entire cart with the product added in the local storage, addToCart is the reducer and carItems is the object that has the payload inside the reducer. so we get it from local storage in store for the initial state. */
    ////console.log(`from product add to cart action ${JSON.stringify(response.data[0])}`);
    /* {"id":290747,"title":"Large Rainbow - Jenga","vendor":"Gamer","price":2.9,"category":"Board & Card Games","stock":7} */
    /* this is for falsafa, just to catch the exact message from the response to display it */
  };

export const addToCartActin =
  (productId, quantity) => async (dispatch, getState) => {
    /* dispstch is what send the actions type along with its payload which is respose.data to the reducer. and three actions are usually created, but here i dont know why one. getState will bring all the lists from the store to get the cart items to save tgem along with the added one to the local storage */

    const response = await axios.get(
      `https://feehla-checkout-api.herokuapp.com/product/${productId}`
    );
    /* 
    for production repace with thishttps://feehla-checkout-api.herokuapp.com/
    
    devh=ttp://localhost:3000/
    */

    let product = response.data[0];
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        id: product.id,
        name_en: product.title,
        price: product.price,
        stock: product.stock,
        image: product.image,
        vendor: product.vendor,
        vendor_id: product.vendor_id,
        quantity,
      },
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().addToCart.cartItems)
    );
    /* we save the entire cart with the product added in the local storage, addToCart is the reducer and carItems is the object that has the payload inside the reducer. so we get it from local storage in store for the initial state. */
    ////console.log(`from product add to cart action ${JSON.stringify(response.data[0])}`);
    /* {"id":290747,"title":"Large Rainbow - Jenga","vendor":"Gamer","price":2.9,"category":"Board & Card Games","stock":7} */
    /* this is for falsafa, just to catch the exact message from the response to display it */
  };

export const removeFromCartAction =
  (productId) => async (dispatch, getState) => {
    /* dispstch is what send the actions type along with its payload which is respose.data to the reducer. and three actions are usually created, but here i dont know why one. getState will bring all the lists from the store to get the cart items to save tgem along with the added one to the local storage */

    dispatch({
      type: "REMOVE_FROM_CART",
      payload: productId,
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().addToCart.cartItems)
    );
    /* we save the entire cart with the product added in the local storage, addToCart is the reducer and carItems is the object that has the payload inside the reducer. so we get it from local storage in store for the initial state. */
  };
export const shippingMethodAction = (data) => async (dispatch, getState) => {
  /* dispstch is what send the actions type along with its payload which is respose.data to the reducer. and three actions are usually created, but here i dont know why one. getState will bring all the lists from the store to get the cart items to save tgem along with the added one to the local storage */

  dispatch({
    type: "SHIPPING_METHOD",
    payload: data,
  });
  localStorage.setItem(
    "shippingMethod",
    JSON.stringify(getState().addToCart.shippingMethod)
  );
};
export const locationAction = (data) => async (dispatch, getState) => {
  dispatch({
    type: "LOCATION",
    payload: data,
  });
};
export const saveAddressAction = (data) => async (dispatch, getState) => {
  /* axios shuold be here */
  dispatch({
    type: "SAVE_ADDRESS",
    payload: data,
  });
  localStorage.setItem("address", JSON.stringify(data));
};
export const selectAddressAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: "ADDRESSES_REQUEST",
    });
    const response = await axios.get(
      "https://feehla-checkout-api.herokuapp.com/checkout/select-address",
      { params: { userId: id } }
    );
    /* change the url from localhost3000 for deployment */
    dispatch({ type: "ADDRESSES_SUCCESS", payload: response.data });
    /* set the type to product list success after the server response. which translates to not loading and filled data product array in reducer */
    ////console.log(`from product action ${JSON.stringify(response.data)}`);
  } catch (error) {
    dispatch({
      type: "ADDRESSES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    /* this is for falsafa, just to catch the exact message from the response to display it */
  }
};
export const getCartAction =
  (cartId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: "GET_CART_REQUEST",
      });
      const response = await axios.get(
        "https://feehla-checkout-api.herokuapp.com/checkout/get-cart-plug",
        { params: { cartId: cartId } }
      );
      
      dispatch({
      type: "GET_CART_SUCCESS",
      payload: response.data
    });
    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().addToCart.cartItems)
    );
    dispatch({
      type: "RECORD_CART_ID",
      payload: cartId
    });
    localStorage.setItem(
      "cartId",
      JSON.stringify(getState().addToCart.cartId)
    );

    } catch (error) {
      dispatch({
        type: "GET_CART_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  
  };
