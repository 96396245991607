import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

/* guest url
https://checkout.feehla.com
/app/?auth_token=&sl_id=10741614&address=eyJpZCI6bnVsbCwidGl0bGUiOiJhcHAgZ3Vlc3QiLCJ1c2VyX2lkIjpudWxsLCJidWlsZGluZyI6bnVsbCwicm9hZCI6bnVsbCwiYmxvY2siOm51bGwsImFyZWEiOiJPdGhlciBBcmVhIiwiY291bnRyeSI6IkJIIiwibWVtbyI6IiIsInBob25lX251bWJlciI6IjMyMjIwMTY5IiwidGV4dCI6bnVsbCwiaXNfZGVmYXVsdCI6bnVsbCwiZW1haWwiOiJuZTNlbWlAZ21haWwuY29tIiwicGVyc29uX25hbWUiOiJBcHAgdXNlciIsImd1ZXN0X25hbWUiOiJBcHAgdXNlciIsImFkZHJlc3NfdGV4dCI6IkF2ZW51ZSAzOCwgQSdhbGksIEJhaHJhaW4iLCJsb2NhdGlvbl9zdHJpbmciOm51bGwsImxvY2F0aW9uX2xhdGl0dWRlIjoyNi4xNDU4MTg3ODg5ODQyNTQsImxvY2F0aW9uX2xvbmdpdHVkZSI6NTAuNTE2NDg2ODc1NzEyODd9

user url 
https://checkout.feehla.com/app/?auth_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkaXNwbGF5TmFtZSI6Ik1vaGFtbWVkIEFsbm9haW1pIiwiaWQiOjQzMTM4LCJpYXQiOjE2NjUzNTIzMDd9.O_vnKO5zGpOTMmGO6M8fNQgeitTXuz8et0j_FoRIU6U&sl_id=10741614&address=eyJpZCI6Ijg2NDQiLCJ0aXRsZSI6IkFwcCBhZGRyZXNzIiwidXNlcl9pZCI6IjQzMTM4IiwiYnVpbGRpbmciOiIiLCJyb2FkIjoiIiwiYmxvY2siOiIiLCJhcmVhIjoiT3RoZXIgQXJlYSIsImNvdW50cnkiOiJCQUgiLCJtZW1vIjoiIiwicGhvbmVfbnVtYmVyIjoiMzIyMjAxNjkiLCJ0ZXh0IjoiQnVpbGRpbmc6ICwgUm9hZDogLCBCbG9jazogLCBPdGhlciBBcmVhIiwiaXNfZGVmYXVsdCI6dHJ1ZSwiZW1haWwiOm51bGwsInBlcnNvbl9uYW1lIjpudWxsLCJndWVzdF9uYW1lIjpudWxsLCJhZGRyZXNzX3RleHQiOiJBdmVudWUgMzgsIEEnYWxpLCBCYWhyYWluIiwibG9jYXRpb25fc3RyaW5nIjoiIiwibG9jYXRpb25fbGF0aXR1ZGUiOjI2LjE0Njc0MTg1NzczNjE3OCwibG9jYXRpb25fbG9uZ2l0dWRlIjo1MC41MTY5NzUwMzc3NTM1OH0=
*/

import { useDispatch, useSelector } from "react-redux";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, Form } from 'formik';
import '../shipping/Shipping.css'
import { addToCartAction, getCartAction, saveAddressAction, shippingMethodAction } from '../../actions/addToCartAction';
import CheckoutSteps from '../../components/checkoutSteps/CheckoutSteps';
import OrderSummary from '../../components/orderSummary/OrderSummary';
import { useEffect, useState } from 'react';
import SideBarSummary from '../../components/sideBarSummary/SideBarSummary.jsx';
import Navigation from '../navigation/navigation';
import MainHeader from '../mainHeader/MainHeader';
import { signInPlugAction } from '../../actions/userAction';
import { isEmpty } from "lodash";
import LoadingSpinner from '../../components/Spinner/Spinner';
import Announcement from '../../components/Announcement/Announcement';
import AnnouncementDesktop from '../../components/AnnouncementDesktop/AnnouncementDesktop';
import { useBackListener } from '../../components/back/back';
import { ExpressOn } from '../../utils/expressOn';
import { Base64 } from 'js-base64';
const AppShipping = () => {
    let navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const authToken = searchParams.get('auth_token');
    const cartId = searchParams.get('sl_id');
    const address64 = Base64.decode(searchParams.get('address'))

    //alert(`address ${address64} /////////// auth ${authToken}///// cartId ${cartId}`)

    // const userId = searchParams.get('user_id');
    const [isLoading, setIsLoading] = useState(true);
    const [shopClosed, setShopClosed] = useState([]);
    let out = false
    console.log(`out from shipping is ${out}`)
    localStorage.clear()
    localStorage.setItem('cartId', JSON.stringify(cartId))
    let timingsMsgs = []
    //console.log(`auth token ${authToken} cartId ${cartId}`)
    const dispatch = useDispatch()
    const addToCart = useSelector((state) => state.addToCart)
    const cartItems = addToCart.cartItems
    const shippingRedux = addToCart.shippingMethod.shippingCharge
    //const shippingMethod = addToCart.shippingMethod
    const user = useSelector((state) => state.user)
    //const { id } = user.userInfo;
    const { loading, error, userInfo } = user
    const cartLoading = addToCart.loading
    ////console.log('from shipping user state id ', id);
    const itemsTotalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
    //console.log(`from shippingjsx items total price ${itemsTotalPrice}`)
    const uniqueVendors = [...
        new Set(cartItems.map(
            (item) => {
                console.log(`from shippingjsx each vendor ${item.vendor}`)
                return item.vendor
            })
        )];
    console.log(`from shippingjsx uniqueVendors${uniqueVendors.length}`)
    const initialValues = {
        shippingCharge: "1"
    };
    //we have a problem here items still unknown
    const [shippingState, setShippingState] = useState(itemsTotalPrice >= 15 ? 0 : 1)
    //console.log(`shipping state ${shippingState}`)

    useEffect(() => {
        if (!authToken==''&&!authToken==null) {
           // alert('it shouldnt log ', authToken);
            dispatch(signInPlugAction(authToken))
        }

            dispatch(getCartAction(cartId))

        

    }, [dispatch])



    useEffect(() => {
        if (!cartLoading) {
            //console.log(`from use effect loading is ${cartLoading} we got cartItems ${JSON.stringify(cartItems)}`)
            if (shippingRedux === 0) {
                //console.log(`from use effect shippingRedux ${shippingRedux}`)
                setShippingState(shippingRedux)
            } else if (shippingRedux > 0) {
                setShippingState(shippingRedux)

            } else {
                setShippingState(itemsTotalPrice >= 15 ? 0 : 1)

            }
            let vendorsTiming = cartItems.map((item) => {
                let msg = ExpressOn(item.business_hours)
                console.log(`msg returns ${msg}`)
                if (msg) {
                    timingsMsgs.push(`${item.vendor} ${msg}`)

                }
                if (item.name_en == '(Out of stock)') {
                    const ToastImg = () => {
                        return item.image ? item.image.split('/')[0] !== '[aws_images_bucket]' ? (
                            <div className='toastContainer'>

                                <img src={'https://feehla-uploads-bh.s3.me-south-1.amazonaws.com/images-300x300/' + item.image.split('/images/')[1]} className="imgtoast" alt="" />
                                <div>This item is out of stock</div>
                            </div>
                        )
                            : (
                                <div className='toastContainer'>
                                    <img src={'https://s3.us-east-2.amazonaws.com/feehla-images-uploads/images-300x300/' + item.image.split('/images/')[1]} className="imgtoast" alt="" />
                                    <div>This item is out of stock</div>
                                </div>
                            ) : <div>Some items are out of stock</div>



                    }

                    toast.warn(<ToastImg />);
                    out = true;

                }
            })
            if (timingsMsgs.length) {
                setShopClosed(timingsMsgs)
                console.log(`timings closed ${timingsMsgs}`)

            }

            setIsLoading(false)
            //localStorage.setItem('shippingCharge',JSON.stringify(shippingState))
        }




    }, [cartLoading])

    useBackListener(({ location }) => {
        //console.log("Navigated Back", { location });
        navigate("https://feehla.com/", { replace: true });
    }
    )
    const getTotalAmount = (data) => {
        let shippingMethod = '';
        let shippingCharge = 1;

        if (data.shippingCharge == '2') {
            shippingCharge = Number(data.shippingCharge) * uniqueVendors.length;
            //shippingMethod = `Express Shipping 2 hours from ${uniqueVendors.length} shops `
            shippingMethod = 'express';

        } else if (data.shippingCharge == '1') {
            if (itemsTotalPrice >= 15) {
                shippingCharge = 0
                //shippingMethod = 'Normal Shipping 1-3 days';
                shippingMethod = 'standard';


            } else {
                shippingCharge = 1
                // shippingMethod = 'Normal Shipping 1-3 days';
                shippingMethod = 'standard';

            }
        } else {
            shippingCharge = 4.4
            shippingMethod = 'International';

        }
        let calculatedData = { 'shippingCharge': shippingState, 'shippingMethod': shippingState > 1 && shippingState !== 4.4 ? (`express`) : shippingState === 4.4 ? ('International Shipping') : ('standard') }

        //console.log(`dispatched shipping state is ${JSON.stringify(calculatedData)}`)
        dispatch(shippingMethodAction(calculatedData))

            ////console.log('from shipping jsx address response ', response)
            dispatch(saveAddressAction(JSON.parse(address64)))
            navigate('/app-payment')





        ////console.log('fuck');
        //////console.log(`from shippingjsx shippingstate${addToCart.shippingMethod}`)
    };


    return (

        <>
            <ToastContainer
                position="top-center"
                className={'toast'}
                autoClose={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="light"

            />            {/* Same as */}
            <ToastContainer />
            <AnnouncementDesktop></AnnouncementDesktop>
            {isLoading ? <LoadingSpinner></LoadingSpinner> : (
                <>
                    <OrderSummary shippingPrice={shippingState} itemsTotalPrice={itemsTotalPrice} outOfStock={out}></OrderSummary>

                    <div className='content'>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={getTotalAmount}
                        >{({ values }) => (
                            /* maybe this is to get the values from the fields and pass it to the total amount div  */
                            <Form className='wrap'>
                                <div className="main">
                                    <MainHeader></MainHeader>


                                    {/* call thr name of the field to get its value */}
                                    <div className='method'>
                                        <h2>Shipping method</h2>
                                        <div id="my-radio-group">{/* {JSON.stringify(cartItems)} */}</div>
                                        <div role="group" aria-labelledby="my-radio-group" className='contentField'>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="shippingCharge" value="1" checked={shippingState == 1 || shippingState == 0 ? true : false}

                                                            onChange={(e) => {
                                                                /* we grt onchange from formik props */
                                                                ////console.log(`shippingState ${shippingState} target ${e.target.value}`)
                                                                ;
                                                                //console.log(`shippingState ${shippingState} target ${e.target.value}`)
                                                                itemsTotalPrice >= 15 ? setShippingState(0) : setShippingState(1)

                                                                /* we will set the address state to the radio button e selected value which is the current address id so they can equual and we get rerender  */

                                                            }}
                                                        />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioDays'>Normal Shipping 1-3 days</span><span className='radioAccessory'><span className='radioBd'>{itemsTotalPrice >= 15 ? 'BD 0' : 'BD 1'} </span></span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="shippingCharge" value="2" disabled={shopClosed.length} checked={shippingState < 2 || shippingState === 4.4 ? false : true} onChange={(e) => {
                                                            /* we grt onchange from formik props */

                                                            let shippingPrice = Number(e.target.value) * uniqueVendors.length;
                                                            //console.log(`shippingState ${shippingState} target ${e.target.value} shipping total ${shippingPrice}`)
                                                            setShippingState(shippingPrice)

                                                            /* we will set the address state to the radio button e selected value which is the current address id so they can equual and we get rerender  */

                                                        }}
                                                        />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className={shopClosed.length ? 'radioDays disabledText' : 'radioDays'}>  Express Shipping 2 hours from {uniqueVendors.length} shop(s) </span> <span className='radioAccessory'><span className={shopClosed.length ? 'radioBd disabledText' : 'radioBd'}> BD {uniqueVendors.length * 2}</span></span>
                                                        {shopClosed.length ? (<p className='field__message field__message--error'>{shopClosed[0]}</p>) : ''}
                                                    </div>
                                                </label>
                                            </div>
                                            <div className='contentRow'>
                                                <label className='radioWrapper'>
                                                    <div className='radioInput'>
                                                        <Field className='radioButton' type="radio" name="shippingCharge" value="4.4" checked={shippingState === 4.4 ? true : false} onChange={(e) => {
                                                            /* we grt onchange from formik props */

                                                            let shippingPrice = 4.4;
                                                            //console.log(`shippingState ${shippingState} target ${e.target.value} shipping total ${shippingPrice}`)
                                                            setShippingState(shippingPrice)

                                                            /* we will set the address state to the radio button e selected value which is the current address id so they can equual and we get rerender  */

                                                        }}
                                                        />
                                                    </div>
                                                    <div className='radioLabel'>
                                                        <span className='radioDays'>  International Shipping 4 to 10 days starting from </span> <span className='radioAccessory'><span className='radioBd'> BD 4.4</span></span>
                                                    </div>
                                                </label>
                                            </div>

                                            {/*                     <div>Picked: {data.picked}</div>
     */}                  </div>
                                    </div>

                                    <div className='stepFooter'>

                                        <button className='stepButton' type="submit"><span>Continue</span></button>
                                    </div>
                                </div>
                                <SideBarSummary shippingPrice={shippingState}></SideBarSummary>

                            </Form>
                        )}

                        </Formik>
                    </div>
                </>
            )}
        </>
    );



}




export default AppShipping;