export const createOrderReducer = (state = {order:[]}, action) => {
  // reducer will just adjust the whole creste object and put it in stote. return is the final object.. as seen below , will return the previous state, loading, success, order payload appended to previous order
  switch (action.type) {
    case "CREATE_ORDER_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_ORDER_SUCCESS":
      return {
        ...state,

        loading: false,
        success: true,
        order: [...state.order?state.order:[], action.payload],
      };
      case "CREATE_APP_ORDER_SUCCESS":
        return {
          ...state,
  
          loading: false,
          success: true,
          order: [...state.order?state.order:[], action.payload],
        };
  
    case "CREATE_ORDER_FAIL":
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
