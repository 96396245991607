/* first step of redux */
import axios from "axios";
export const productDetailsAction = (productId) => async (dispatch) => {
  /* this happens with a function returned by a function, and dispstch is what send the actions type along with its payload which is respose.data. and three actions are usually created */
  try {
    dispatch({ type: "PRODUCT_DETAILS_REQUEST" });
    /* set the type tp product list request before the server request. which translates to loading and empty product array in reducer */
    const response = await axios.get(
      `https://feehla-checkout-api.herokuapp.com/product/${productId}`
    );
     /* 
    for production repace with this
    https://feehla-checkout-api.herokuapp.com/ */
    
    dispatch({ type: "PRODUCT_DETAILS_SUCCESS", payload: response.data[0] });
    /* set the type to product DETAILS success after the server response. which translates to not loading and filled data product array in reducer */
    ////console.log(`from product details action ${JSON.stringify(response.data[0])}`);
  } catch (error) {
    dispatch({
      type: "PRODUCT_DETAILS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    /* this is for falsafa, just to catch the exact message from the response to display it */
  }
};
